<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay> 
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">



                    <v-row align="center" class="pa-0 ma-0">

                        <v-col class="ma-0 pa-0" cols="12" xs="6" sm="7" md="8" lg="8" xl="8">
                            <v-tabs v-model="tab" background-color="transparent" class="ml-1 my-0 pa-0">
                                <v-tabs-slider color="transparent"></v-tabs-slider>
                                <v-tab class="pa-0 " >
                                    <span :class="tab==0?'tabText':'tabTextDisable'" class=" mx-6">{{ 'Nómina' }}</span>
                                </v-tab>
                                <v-tab  class="pa-0 " >
                                    <span :class="tab==1?'tabText':'tabTextDisable'" class=" mx-4">{{ 'Aguinaldo / Finiquito'}}</span>
                                </v-tab>
                                <v-tab>
                                    <span :class="tab==2?'tabText':'tabTextDisable'" class=" mx-4">{{ 'PTU' }}</span>
                                </v-tab>
                            </v-tabs>
                        </v-col>

                        <v-col v-if="mostrarProceso" class="ma-0 pa-0 d-flex justify-space-between cssTitleProgress" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span>{{nominaPorcentaje<100?'Timbrado de nómina en proceso':'Timbrado de nómina finalizado'}}</span> 
                            <span>{{'Procesado '+nominaProcesados+' de '+nominaTotal}}</span>
                        </v-col>
                        <v-col v-if="mostrarProceso" class="ma-0 pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-progress-linear
                                :value="nominaPorcentaje"
                                height="10"
                                rounded
                                background-color="#DADADA"
                                :color="nominaPorcentaje<100?'#F2C94C':'#219653'"
                            >
                                <span class="cssTitleProgress">{{ Math.ceil(nominaPorcentaje) }}%</span>
                            </v-progress-linear>
                        </v-col>
                    </v-row>

                    <v-tabs-items v-model="tab" class="mb-10">
                        <v-tab-item :key="0" style="background:#EFEFEF;" class="py-5">
                            <data-table
                                ref="tabla1"
                                :tableName="'Tabla'"
                                :url="url"
                                :columns="columns"
                                :filters="filters"
                                @setFilters="setFilters"
                                @loadModalData="loadModalData"
                                :showAdvancedFilters="true"
                                :perPage="[10,25,50,100]"
                            >
                                <template slot="actionButtonsLeft">
                                    <div class="botonAgregar">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    fab
                                                    color="#FFFFFF"
                                                    small
                                                    elevation="0"
                                                    dark
                                                    @click="actualizarTabla('tabla1')"
                                                >
                                                    <v-icon color="rgba(0, 0, 0, 0.54)">mdi-reload</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Actualizar tabla</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <template slot="filters">
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                        <v-autocomplete
                                            outlined
                                            label="Clientes"
                                            :items="itemsClientes"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="cliente_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="itemsEmpresas"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Empresa"
                                            persistent-hint
                                            v-model="empresa_value"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            label="Tipo de nómina"
                                            :items="itemsTiposNominas"
                                            item-text="claveDescripcion"
                                            item-value="id"
                                            persistent-hint
                                            v-model="tipo_nomina_id"
                                            class="pa-0 ma-0"
                                            id="tipoNominaID"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Periodo"
                                            :items="itemsPeriodos"
                                            item-text="numero"
                                            item-value="id"
                                            persistent-hint
                                            v-model="periodo_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12">
                                        <div class="RangoIngreso">
                                            <span>Rango de fechas</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_inicio"
                                            label="Fecha inicio"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_final"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFinal"
                                        />
                                    </v-col>
                                </template>
                                <tbody slot="body" slot-scope="{ data }">
                                    <tr :key="item.id" v-for="item in data">
                                        <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                        <td>
                                            <div>
                                                <v-menu v-model="item.menu" buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones menu-width"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="transparent"
                                                            rounded
                                                            class="elevation-0 v-btn-periodo"
                                                            :ripple="false" 
                                                        >
                                                            <span class="span-periodo">{{item.numero_periodo}}</span>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                            <v-btn
                                                                @click="item.menu = false"
                                                                small
                                                                icon
                                                                text
                                                                :ripple="false" 
                                                                class="v-btn-cerrar mr-2"
                                                            >
                                                                <v-icon class="icono-cerrar"></v-icon>
                                                            </v-btn>
                                                        </div>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-row class="pa-0 ma-0">
                                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                            <p class="informacionGeneralTitulo">Información del periodo</p>
                                                                        </v-col> 
                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Periodicidad:</p>
                                                                                <p class="informacionGeneralText">{{item.periodicidad ? item.periodicidad:"Información faltante*"}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Código:</p>
                                                                                <p class="informacionGeneralText">{{item.numero_periodo}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombre_empresa}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Año:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombreAnio}}</p>
                                                                            </v-col>
                                                                        </v-col>

                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha inicial:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_inicial, 'modal')}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha final:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_final, 'modal')}}</p>
                                                                            </v-col>
                                                                            
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                                                <p class="informacionGeneralText">{{item.mes_de_acomulacion}}</p>
                                                                            </v-col>
                                                                        </v-col>
                                                                    </v-row>                           
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </td>
                                        <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                        
                                        <td>
                                            <div class="d-flex flex-row justify-center">

                                                <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon 
                                                            fab
                                                            dark
                                                            small
                                                            class="elevation-0"
                                                        >
                                                            <template  v-for="(item,valor) in attrs" >
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                            </template>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <template v-if="!item.procesando_timbres">
                                                            <!-- <v-list-item
                                                                @click="(item.tipo_periodo_especial == 'AGUINALDO' || item.tipo_periodo_especial == 'FINIQUITO') ? reporteDeEmpleados(item) : descargarReporte(item, 'reporte')"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item> -->

                                                            <v-list-item
                                                                @click="reporteDeEmpleados(item)"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item>
                                                        </template>
                                                        <v-list-item
                                                            @click="vistaComplementos(item)"
                                                        >
                                                            <v-list-item-title>Complementos</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            @click="detalleAcumulado(item)"
                                                        >
                                                            <v-list-item-title>Detalle del periodo</v-list-item-title>
                                                        </v-list-item>

                                                        <!--    nuevos    -->

                                                        
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == true"
                                                            @click="abrirModalCalcular(item, 'RECALCULAR')"
                                                        >
                                                            <v-list-item-title>Recalcular</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == false"
                                                            @click="abrirModalTimbre(item)"
                                                        >
                                                            <v-list-item-title>Acumular</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-tooltip top v-if="item.requiere_recalcular">
                                                    <template v-slot:activator="{ on }">
                                                        <v-img
                                                            v-on="on"
                                                            class="mr-n8 mt-2 ml-3"
                                                            style="margin-bottom:2px"
                                                            max-height="23"
                                                            max-width="23"
                                                            src="/static/svg/signoExclamacion.svg"
                                                        ></v-img>
                                                    </template>
                                                    <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </data-table>
                        </v-tab-item>
                        <v-tab-item :key="1" style="background:#EFEFEF;" class="py-5">
                            <data-table
                                ref="tabla2"
                                :tableName="'Tabla'"
                                :url="url"
                                :columns="columns"
                                :filters="filtersFA"
                                @setFilters="setFilters"
                                @loadModalData="loadModalData"
                                :showAdvancedFilters="true"
                                :perPage="[10,25,50,100]"
                            >
                                <template slot="actionButtonsLeft">
                                    <div class="botonAgregar">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    fab
                                                    color="#FFFFFF"
                                                    small
                                                    elevation="0"
                                                    dark
                                                    @click="actualizarTabla('tabla2')"
                                                >
                                                    <v-icon color="rgba(0, 0, 0, 0.54)">mdi-reload</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Actualizar tabla</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <template slot="filters">
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                        <v-autocomplete
                                            outlined
                                            label="Clientes"
                                            :items="itemsClientes"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="cliente_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="itemsEmpresas"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Empresa"
                                            persistent-hint
                                            v-model="empresa_value"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            label="Tipo de nómina"
                                            :items="itemsTiposNominas"
                                            item-text="claveDescripcion"
                                            item-value="id"
                                            persistent-hint
                                            v-model="tipo_nomina_id"
                                            class="pa-0 ma-0"
                                            id="tipoNominaID"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Periodo"
                                            :items="itemsPeriodos"
                                            item-text="numero"
                                            item-value="id"
                                            persistent-hint
                                            v-model="periodo_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12">
                                        <div class="RangoIngreso">
                                            <span>Rango de fechas</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_inicio"
                                            label="Fecha inicio"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_final"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFinal"
                                        />
                                    </v-col>
                                </template>
                                <tbody slot="body" slot-scope="{ data }">
                                    <tr :key="item.id" v-for="item in data">
                                        <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                        <td>
                                            <div>
                                                <v-menu v-model="item.menu" buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones menu-width"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="transparent"
                                                            rounded
                                                            class="elevation-0 v-btn-periodo"
                                                            :ripple="false" 
                                                        >
                                                            <span class="span-periodo">{{item.numero_periodo}}</span>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                            <v-btn
                                                                @click="item.menu = false"
                                                                small
                                                                icon
                                                                text
                                                                :ripple="false" 
                                                                class="v-btn-cerrar mr-2"
                                                            >
                                                                <v-icon class="icono-cerrar"></v-icon>
                                                            </v-btn>
                                                        </div>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-row class="pa-0 ma-0">
                                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                            <p class="informacionGeneralTitulo">Información del periodo</p>
                                                                        </v-col> 
                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Periodicidad:</p>
                                                                                <p class="informacionGeneralText">{{item.periodicidad ? item.periodicidad:"Información faltante*"}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Código:</p>
                                                                                <p class="informacionGeneralText">{{item.numero_periodo}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombre_empresa}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Año:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombreAnio}}</p>
                                                                            </v-col>
                                                                        </v-col>

                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha inicial:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_inicial, 'modal')}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha final:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_final, 'modal')}}</p>
                                                                            </v-col>
                                                                            
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                                                <p class="informacionGeneralText">{{item.mes_de_acomulacion}}</p>
                                                                            </v-col>
                                                                        </v-col>
                                                                    </v-row>                           
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </td>
                                        <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                        
                                        <td >
                                            <div class="d-flex flex-row justify-center">

                                                <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon 
                                                            fab
                                                            dark
                                                            small
                                                            class="elevation-0"
                                                        >
                                                            <template  v-for="(item,valor) in attrs" >
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                            </template>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <template v-if="!item.procesando_timbres">
                                                            <!-- <v-list-item
                                                                @click="(item.tipo_periodo_especial == 'AGUINALDO' || item.tipo_periodo_especial == 'FINIQUITO') ? reporteDeEmpleados(item) : descargarReporte(item, 'reporte')"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item> -->

                                                            <v-list-item
                                                                @click="reporteDeEmpleados(item)"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item>
                                                        </template>
                                                        <v-list-item
                                                            @click="vistaComplementos(item)"
                                                        >
                                                            <v-list-item-title>Complementos</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            @click="detalleAcumulado(item)"
                                                        >
                                                            <v-list-item-title>Detalle del periodo</v-list-item-title>
                                                        </v-list-item>

                                                        <!--    nuevos    -->
                                                        <v-list-item
                                                            v-if="item.generado"
                                                            @click="abrirModalAF('update', item)"
                                                        >
                                                            <v-list-item-title>Editar</v-list-item-title>
                                                        </v-list-item>
                                                        
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == true"
                                                            @click="abrirModalCalcular(item, 'RECALCULAR')"
                                                        >
                                                            <v-list-item-title>Recalcular</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == false"
                                                            @click="abrirModalTimbre(item)"
                                                        >
                                                            <v-list-item-title>Acumular</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-tooltip top v-if="item.requiere_recalcular">
                                                    <template v-slot:activator="{ on }">
                                                        <v-img
                                                            v-on="on"
                                                            class="mr-n8 mt-2 ml-3"
                                                            style="margin-bottom:2px"
                                                            max-height="23"
                                                            max-width="23"
                                                            src="/static/svg/signoExclamacion.svg"
                                                        ></v-img>
                                                    </template>
                                                    <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </data-table>
                        </v-tab-item>
                        <v-tab-item :key="2" style="background:#EFEFEF;" class="py-5">
                            <data-table
                                ref="tabla3"
                                :tableName="'Tabla'"
                                :url="url"
                                :columns="columns"
                                :filters="filtersPTU"
                                @setFilters="setFilters"
                                @loadModalData="loadModalData"
                                :showAdvancedFilters="true"
                                :perPage="[10,25,50,100]"
                            >
                                <template slot="actionButtonsLeft">
                                    <div class="botonAgregar">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    fab
                                                    color="#FFFFFF"
                                                    small
                                                    elevation="0"
                                                    dark
                                                    @click="actualizarTabla('tabla3')"
                                                >
                                                    <v-icon color="rgba(0, 0, 0, 0.54)">mdi-reload</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Actualizar tabla</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <template slot="filters">
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">  
                                        <v-autocomplete
                                            outlined
                                            label="Clientes"
                                            :items="itemsClientes"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="cliente_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="itemsEmpresas"
                                            item-text="nombre"
                                            item-value="id"
                                            label="Empresa"
                                            persistent-hint
                                            v-model="empresa_value"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            label="Tipo de nómina"
                                            :items="itemsTiposNominas"
                                            item-text="claveDescripcion"
                                            item-value="id"
                                            persistent-hint
                                            v-model="tipo_nomina_id"
                                            class="pa-0 ma-0"
                                            id="tipoNominaID"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Periodo"
                                            :items="itemsPeriodos"
                                            item-text="numero"
                                            item-value="id"
                                            persistent-hint
                                            v-model="periodo_value"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12">
                                        <div class="RangoIngreso">
                                            <span>Rango de fechas</span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_inicio"
                                            label="Fecha inicio"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaInicio"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            v-model="fecha_final"
                                            label="Fecha final"
                                            placeholder="dd/mm/aaaa"
                                            ref="FechaFinal"
                                        />
                                    </v-col>
                                </template>
                                <tbody slot="body" slot-scope="{ data }">
                                    <tr :key="item.id" v-for="item in data">
                                        <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                        <td>
                                            <div>
                                                <v-menu v-model="item.menu" buttom :close-on-content-click="false" class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones menu-width"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="transparent"
                                                            rounded
                                                            class="elevation-0 v-btn-periodo"
                                                            :ripple="false" 
                                                        >
                                                            <span class="span-periodo">{{item.numero_periodo}}</span>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                                                            <v-btn
                                                                @click="item.menu = false"
                                                                small
                                                                icon
                                                                text
                                                                :ripple="false" 
                                                                class="v-btn-cerrar mr-2"
                                                            >
                                                                <v-icon class="icono-cerrar"></v-icon>
                                                            </v-btn>
                                                        </div>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>
                                                                    <v-row class="pa-0 ma-0">
                                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                                            <p class="informacionGeneralTitulo">Información del periodo</p>
                                                                        </v-col> 
                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Periodicidad:</p>
                                                                                <p class="informacionGeneralText">{{item.periodicidad ? item.periodicidad:"Información faltante*"}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Código:</p>
                                                                                <p class="informacionGeneralText">{{item.numero_periodo}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombre_empresa}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Año:</p>
                                                                                <p class="informacionGeneralText text-wrap">{{item.nombreAnio}}</p>
                                                                            </v-col>
                                                                        </v-col>

                                                                        <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha inicial:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_inicial, 'modal')}}</p>
                                                                            </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Fecha final:</p>
                                                                                <p class="informacionGeneralText">{{dateFormat(item.fecha_final, 'modal')}}</p>
                                                                            </v-col>
                                                                            
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                                                <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                                                <p class="informacionGeneralText">{{item.mes_de_acomulacion}}</p>
                                                                            </v-col>
                                                                        </v-col>
                                                                    </v-row>                           
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </td>
                                        <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                        
                                        <td>
                                            <div class="d-flex flex-row justify-center">

                                                <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon 
                                                            fab
                                                            dark
                                                            small
                                                            class="elevation-0"
                                                        >
                                                            <template  v-for="(item,valor) in attrs" >
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                            </template>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <template v-if="!item.procesando_timbres">
                                                            <!-- <v-list-item
                                                                @click="(item.tipo_periodo_especial == 'AGUINALDO' || item.tipo_periodo_especial == 'FINIQUITO') ? reporteDeEmpleados(item) : descargarReporte(item, 'reporte')"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item> -->

                                                            <v-list-item
                                                                @click="reporteDeEmpleados(item)"
                                                            >
                                                                <v-list-item-title>Reporte de empleados</v-list-item-title>
                                                            </v-list-item>
                                                        </template>
                                                        <v-list-item
                                                            @click="detalleAcumulado(item)"
                                                        >
                                                            <v-list-item-title>Detalle del periodo</v-list-item-title>
                                                        </v-list-item>

                                                        <!--    nuevos    -->
                                                        <v-list-item
                                                            v-if="item.generado"
                                                            @click="abrirModalPTU(item)"
                                                        >
                                                            <v-list-item-title>Editar</v-list-item-title>
                                                        </v-list-item>
                                                        
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == true"
                                                            @click="abrirModalCalcular(item, 'RECALCULAR')"
                                                        >
                                                            <v-list-item-title>Recalcular</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.generado && item.requiere_recalcular == false"
                                                            @click="abrirModalTimbre(item)"
                                                        >
                                                            <v-list-item-title>Acumular</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-tooltip top v-if="item.requiere_recalcular">
                                                    <template v-slot:activator="{ on }">
                                                        <v-img
                                                            v-on="on"
                                                            class="mr-n8 mt-2 ml-3"
                                                            style="margin-bottom:2px"
                                                            max-height="23"
                                                            max-width="23"
                                                            src="/static/svg/signoExclamacion.svg"
                                                        ></v-img>
                                                    </template>
                                                    <span>Los complementos de esta nómina han <br>sido modificados, es necesario recalcular.</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialogDownload" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalDownload"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center">
                            <div class="headerModal">
                                <img :src="imagenDownload" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalDownload }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text>
                            <div class="loading">
                                <lottie :options="defaultOptions" :height="150" :width="150"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>


            <template class="borde-card">
                <v-dialog v-model="dialogCancelar" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-row class="px-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <v-radio-group
                                            v-model="claveMotivo"
                                            mandatory
                                        >
                                            <v-radio
                                                label="Clave: 02: Comprobante emitido con errores sin relación."
                                                value="02"
                                                class="radioLabel"
                                            ></v-radio>
                                            <v-radio
                                                label="Clave 03: No se llevó a cabo la operación"
                                                value="03"
                                                class="radioLabel"
                                            ></v-radio>
                                            <p v-if="claveMotivo=='03'" class="pNota mb-0">Nota: Una vez aplicado el motivo de cancelación con clave "03", 
                                                el sistema no permitirá realizar ningún timbre, ya que este supuesto 
                                                aplica cuando se facturó una operación que no se concreta.
                                            </p>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="cancelarTimbreNomina()"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>



            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Timbres requeridos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                    <th class="errorth">Requeridos</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresRP.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresRP">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>



            <!----------modal calcular --------->
            <template>
                <v-dialog v-model="dialogCalculo" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculo"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagenCalculo" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModalCalculo }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingCalculo">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingCalculo" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row class="pa-0 ma-0">
                                    <template>
                                        <v-col xs="12" sm="12" md="12" class="pa-0 ">
                                          <!--   <v-autocomplete
                                                v-model="modelo_empleados"
                                                :items="items_empleados"
                                                outlined
                                                item-text="nombre_completo"
                                                item-value="id"
                                                label="Todos los empleados"
                                                multiple
                                                @change="cambio_emp()"
                                            >
                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>
                                                        {{
                                                            item.nombre_completo < 9
                                                            ? item.nombre_completo
                                                            : item.nombre_completo.substr(0, 8) + "..."
                                                        }}
                                                    </span>
                                                </v-chip>
                                                <span
                                                    v-if="index === 1"
                                                    class="grey--text caption"
                                                >(+{{ modelo_empleados.length - 1 }} otros)
                                                </span>
                                            </template>
                                            </v-autocomplete> -->
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                :items="items_empleados"
                                                item_text="nombre_completo"
                                                @dataMultiple="(data)=> modelo_empleados = data"
                                                :valorDefecto="modelo_empleados"
                                                label="Todos los empleados"
                                                ref="multEmpleados"
                                            />
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion pt-0">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="btnCerrar">Cerrar</button>
                            <v-btn class="btnGuardar" @click="descargarReporte(itemCalculo,'calcular', accionCalculo)" :disabled="invaliBtnGuardar" :loading="btnGuardar">Guardar</v-btn>
                            <v-btn class="btnGuardar" @click="consultar()" :disabled="invalidBtnConsultar" :loading="btnConsultar">Consultar</v-btn>        
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>


            <template>
                <v-dialog v-model="dialogCalculoTabla" persistent :max-width="tamModal" scrollable class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalCalculoTabla"></v-btn>
                    </template>
                    
                        <v-card class="borde-card">
                            <perfect-scrollbar>
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagenCalculoTabla" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModalCalculoTabla }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text >
                                    <div id="padre" v-show="isLoadingCalculoTabla">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingCalculoTabla" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row class="pa-0 ma-0"> 
                                            <v-row class="pa-0 ma-0 informacionGeneral">
                                                <v-row class="px-9 pt-12 pb-7">
                                                    <template v-if="showDatos === 'NOMINA'">
                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                            <p class="informacionGeneralTitulo">Información global</p>
                                                        </v-col> 
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                            <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                            <p class="informacionGeneralText">{{periodicidad}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                            <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Fecha inicial del periodo:</p>
                                                            <p class="informacionGeneralText">{{dateFormat(periodoFechaInicial, 'modal')}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Fecha final del periodo</p>
                                                            <p class="informacionGeneralText">{{dateFormat(periodoFechaFinal, 'modal')}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de otros pagos:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalOtrosPagos)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                            <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                        </v-col>
                                                    </template>
                                                    <template v-if="showDatos === 'FINIQUITO'">
                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                            <p class="informacionGeneralTitulo">Información global</p>
                                                        </v-col> 
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                            <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                            <p class="informacionGeneralText">{{periodicidad}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                            <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Fecha de pago:</p>
                                                            <p class="informacionGeneralText">{{dateFormat(periodoFechaPago, 'modal')}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                            <p class="informacionGeneralText">{{mesAcumulacion}}</p>
                                                        </v-col>
                                                        
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de otros pagos:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalOtrosPagos)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                            <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                        </v-col>
                                                    </template>
                                                    <template v-if="showDatos === 'AGUINALDO'">
                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                            <p class="informacionGeneralTitulo">Información global</p>
                                                        </v-col> 
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                            <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Tipo de nómina</p>
                                                            <p class="informacionGeneralText">{{periodicidad}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de empleados:</p>
                                                            <p class="informacionGeneralText">{{totalEmpleados}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Fecha de pago:</p>
                                                            <p class="informacionGeneralText">{{dateFormat(periodoFechaPago, 'modal')}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Mes de acumulación:</p>
                                                            <p class="informacionGeneralText">{{mesAcumulacion}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                            <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                        </v-col>
                                                    </template>
                                                    <template v-if="showDatos === 'PTU'">
                                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                                            <p class="informacionGeneralTitulo">Información global</p>
                                                        </v-col> 
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Empresa:</p>
                                                            <p class="informacionGeneralText">{{nombreEmpresa}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de percepciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalPercepciones)}}</p>
                                                        </v-col>
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Total de deducciones:</p>
                                                            <p class="informacionGeneralText">{{formatNum(totalDeducciones)}}</p>
                                                        </v-col>
                                                    
                                                        <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                                            <p class="mb-0 informacionGeneralSubtitle">Importe neto de nómina:</p>
                                                            <p class="informacionGeneralText">{{formatNum(neto)}}</p>
                                                        </v-col>
                                                    </template>
                                                </v-row>
                                            </v-row>
                                            
                                            <template v-if="showDatos === 'NOMINA'">
                                                <simple-table
                                                    class="tablaWidth"
                                                    :rows="itemsEmpleados"
                                                    :columns="headersTableEmpleados"
                                                    :perPage="[10, 25, 50, 100]"
                                                    shortPagination
                                                    :modalTable="true"
                                                >
                                                    <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                        <tr :key="item.id" v-for="(item, index) in data">
                                                            <td class="pa-0">
                                                                <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :valid="item.nomina_valida">
                                                                    <template slot="content">
                                                                        <table class="tbl-child" width="100%">
                                                                            <tbody>
                                                                                <template>
                                                                                    <tr>
                                                                                        <td class="pt-4"><p class="subtitle">Días del periodo: </p></td>
                                                                                        <td class="pt-4"><p class="text">{{item.empleado.dias_periodo}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Días de pago: </p></td>
                                                                                        <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                    </tr>
                                                                                    <tr v-if="item.empleado.dias_vacaciones != 0">
                                                                                        <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                        <td><p class="text">{{item.empleado.dias_vacaciones}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Faltas: </p></td>
                                                                                        <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Incapacidades: </p></td>
                                                                                        <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                    </tr>
                                                                                    <template v-if="item.itemsPercepciones.length != 0">
                                                                                        <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-4" style="color: #1E2245; font-weight: bold;">Percepciones:</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>

                                                                                    <template v-if="item.itemsDeducciones.length != 0">
                                                                                        <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-4" style="color: #1E2245; font-weight: bold;">Deducciones:</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>

                                                                                    <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                        <tr v-if="item.itemsDeducciones.length == 0 && item.itemsPercepciones.length == 0"><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-4" style="color: #1E2245; font-weight: bold;">Otros pagos:</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Total percepciones: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Total deducciones: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir:</td>
                                                                                        <td class="py-4 textNeto" style="color: #1E2245; font-weight: bold;">{{formatNum(item.neto_pagar)}}</td>
                                                                                    </tr> 
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                    </template>
                                                                </expandable-item>

                                                                <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </simple-table>
                                            </template>

                                            <template v-if="showDatos === 'AGUINALDO' || showDatos === 'FINIQUITO'">
                                                <div class="col-lg-12 pa-0 mt-8" v-if="showDatos == 'FINIQUITO'">
                                                    <v-btn
                                                        color="#1E2245" 
                                                        class="btnContrats float-right elevation-0 "
                                                        right
                                                        rounded 
                                                        @click="descargaMasivaFiniquito()"
                                                    >
                                                        Descargar todos
                                                    </v-btn>
                                                </div>

                                                <simple-table
                                                    class="tablaWidth"
                                                    :rows="itemsEmpleados"
                                                    :columns="headersTableEmpleados"
                                                    :perPage="[10, 25, 50, 100]"
                                                    shortPagination
                                                    :modalTable="true"
                                                >
                                                    <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                        <tr :key="item.id" v-for="(item, index) in data">
                                                            <td class="pa-0">
                                                                <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :id="item.empleado.id" :valid="item.nomina_valida" :type="showDatos" @descargar="descargarCartaFiniquito">
                                                                    <template slot="content">
                                                                        <table class="tbl-child" width="100%">
                                                                            <tbody>
                                                                                <template v-if="showDatos === 'AGUINALDO'">
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                        <td><p class="text">{{dateFormat(item.empleado.fecha_alta_imss)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Faltas: </p></td>
                                                                                        <td><p class="text">{{item.faltas}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Incapacidades: </p></td>
                                                                                        <td><p class="text">{{item.incapacidades}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Antiguedad en el año: </p></td>
                                                                                        <td><p class="text">{{item.antiguedad_anio}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Días de aguinaldo pagado: </p></td>
                                                                                        <td><p class="text">{{item.dias_aguinaldo}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                    </tr>
                                                                                    <template v-if="item.itemsPercepciones.length != 0">
                                                                                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>

                                                                                    <template v-if="item.itemsDeducciones.length != 0">
                                                                                        <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>

                                                                                    <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                        <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                        </tr>
                                                                                        <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                            <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                            <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                        </tr>
                                                                                        <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                    </template>

                                                                                    <tr>
                                                                                        <td><p class="subtitle">Total percepciones: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Total deducciones: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.per_exento)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                        <td><p class="text">{{formatNum(item.per_gravado)}}</p></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir: </td>
                                                                                        <td class="py-4" style="color: #1E2245; font-weight: bold;">{{formatNum(item.aguinaldo_neto)}}</td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-if="showDatos === 'FINIQUITO'">
                                                                                    <tr v-if="item.indemnizacion != null">
                                                                                        <td style="width: 50%; border-right:1px solid #EFF1FB; vertical-align: top; padding-left:0px !important">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td class="pb-2 pt-4" style="color: #1E2245; font-weight: bold; font-size: 14px;">Finiquito</td>
                                                                                                </tr>
                                                                                                <tr><td colspan="2"><v-divider class="my-2" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>

                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                                    <td><p class="text">{{dateFormat(item.empleado.fecha_alta_imss)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Fecha de baja: </p></td>
                                                                                                    <td>
                                                                                                        <p class="text" v-if="item.empleado.fecha_baja">{{ dateFormat(item.empleado.fecha_baja)}}</p>
                                                                                                        <p class="text" v-else>{{dateFormat(item.periodo.fecha_baja)}}</p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                                    <td><p class="subtitle">Días de pago: </p></td>
                                                                                                    <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Días de aguinaldo: </p></td>
                                                                                                    <td><p class="text">{{ item.empleado.dias_aguinaldo_finiquito}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                                    <td><p class="text">{{ item.empleado.dias_vacaciones_finiquito}}</p></td>
                                                                                                </tr>
                                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                                    <td><p class="subtitle">Faltas: </p></td>
                                                                                                    <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                                </tr>
                                                                                                <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                                    <td><p class="subtitle">Incapacidades: </p></td>
                                                                                                    <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                                </tr>

                                                                                                <template v-if="item.itemsPercepciones.length != 0">
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>

                                                                                                <template v-if="item.itemsDeducciones.length != 0">
                                                                                                    <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>

                                                                                                <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                                    <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                                        <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>

                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Total deducciones: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Total neto: </td>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;">{{formatNum(item.neto_pagar)}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>

                                                                                        <td align="left" style="width: 50%; vertical-align: top;">
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td class="pb-2 pt-4" style="color: #1E2245; font-weight: bold; font-size: 14px;">Indemnización</td>
                                                                                                </tr>
                                                                                                <tr><td colspan="2"><v-divider class="my-2" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>
                                                                                                
                                                                                                <tr>
                                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Información</td>
                                                                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Días: </p></td>
                                                                                                    <td><p class="text">{{item.indemnizacion.dias?item.indemnizacion.dias:'N/A'}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Tipo: </p></td>
                                                                                                    <td><p class="text">{{item.indemnizacion.tipo_finiquito?item.indemnizacion.tipo_finiquito:'N/A'}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Años de servicio: </p></td>
                                                                                                    <td><p class="text">{{item.indemnizacion.anos_servicio}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Último sueldo mensual ordinario: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.ultimo_sueldo_mensual_ordinario)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Salario diario integrado: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.empleado.sueldo_diario_integrado)}}</p></td>
                                                                                                </tr>

                                                                                                <template v-if="item.itemsPercepcionesIndemnizacion.length != 0">
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIndemnizacion in item.itemsPercepcionesIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                        <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>
                                                                                                
                                                                                                <!-- <tr v-if="item.p_Indemnizacion != 0">
                                                                                                    <td><p class="subtitle">Indemnización: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.p_Indemnizacion)}}</p></td>
                                                                                                </tr>
                                                                                                <tr v-if="item.p_PrimaAntiguedad != 0">
                                                                                                    <td><p class="subtitle">Prima de antigüedad: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.p_PrimaAntiguedad)}}</p></td>
                                                                                                </tr>
                                                                                                <tr v-if="item.p_IndemnizacionServicio != 0">
                                                                                                    <td><p class="subtitle">Indemnización por año de servicio: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.p_IndemnizacionServicio)}}</p></td>
                                                                                                </tr> -->

                                                                                                <template v-if="item.itemsDeduccionesIndemnizacion.length != 0">
                                                                                                    <tr v-if="item.itemsPercepcionesIndemnizacion.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIndemnizacion in item.itemsDeduccionesIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                        <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>
                                                                                                <!-- <tr>
                                                                                                    <td><p class="subtitle">ISR Indemnización: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.total_isr)}}</p></td>
                                                                                                </tr> -->

                                                                                                <template v-if="item.itemsOtrosPagosIndemnizacion.length != 0">
                                                                                                    <tr v-if="item.itemsDeduccionesIndemnizacion.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%"></v-divider></td></tr>
                                                                                                    <tr>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                                        <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepcionesIndemnizacion.length == 0 && item.itemsDeduccionesIndemnizacion.length == 0">Cantidad</td>
                                                                                                    </tr>
                                                                                                    <tr v-for="itemIndemnizacion in item.itemsOtrosPagosIndemnizacion" :key="itemIndemnizacion.id">
                                                                                                        <td><p class="subtitle">{{itemIndemnizacion.nombre}}</p></td>
                                                                                                        <td><p class="text">{{formatNum(itemIndemnizacion.total)}}</p></td>
                                                                                                    </tr>
                                                                                                    <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                                </template>


                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.total_percepciones)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Total deducciones: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.total_deducciones)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.per_exento)}}</p></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                                    <td><p class="text">{{formatNum(item.indemnizacion.per_gravado)}}</p></td>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Total neto: </td>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;">{{formatNum(item.indemnizacion.total_neto)}}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;">Neto a pagar: </td>
                                                                                                    <td style="color: #1E2245; font-weight: 600; font-size: 13px;"> {{netoPago(item.neto_pagar,item.indemnizacion.total_neto)}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <template v-else>
                                                                                        <tr>
                                                                                            <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Información</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Fecha alta IMSS: </p></td>
                                                                                            <td><p class="text">{{dateFormat(item.empleado.fecha_alta_imss)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Fecha de baja: </p></td>
                                                                                            <td>
                                                                                                <p class="text" v-if="item.empleado.fecha_baja">{{ dateFormat(item.empleado.fecha_baja)}}</p>
                                                                                                <p class="text" v-else>{{dateFormat(item.periodo.fecha_baja)}}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Días de pago: </p></td>
                                                                                            <td><p class="text">{{item.empleado.dias_de_pago}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Días de aguinaldo: </p></td>
                                                                                            <td><p class="text">{{ item.empleado.dias_aguinaldo_finiquito}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                            <td><p class="text">{{ item.empleado.dias_vacaciones_finiquito}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Faltas: </p></td>
                                                                                            <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                                        </tr>
                                                                                        <tr v-if="item.empleado.dias_de_pago != 0">
                                                                                            <td><p class="subtitle">Incapacidades: </p></td>
                                                                                            <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                                        </tr>

                                                                                        <template v-if="item.itemsPercepciones.length != 0">
                                                                                            <!-- <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr> -->
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <template v-if="item.itemsDeducciones.length != 0">
                                                                                            <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Deducciones:</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                            <tr v-if="item.itemsDeducciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                            <tr>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Otros pagos:</td>
                                                                                                <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;" v-if="item.itemsPercepciones.length == 0 && item.itemsDeducciones.length == 0">Cantidad</td>
                                                                                            </tr>
                                                                                            <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                                <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                                <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                            </tr>
                                                                                            <tr><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:calc(100% - 35px);"></v-divider></td></tr>
                                                                                        </template>

                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total Percepciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total deducciones: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                            <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                                        </tr>

                                                                                        <tr class="mb-4 pb-4" style="">
                                                                                            <td style="color: #1E2245; font-weight: bold; font-size: 13px; padding-bottom:16px; padding-top:16px;">Neto a pagar: </td>
                                                                                            <td style="color: #1E2245; font-weight: bold; font-size: 13px; padding-bottom:16px; padding-top:16px;">{{formatNum(item.neto_pagar)}}</td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                    </template>
                                                                </expandable-item>

                                                                <hr v-if="index < data.length - 1" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </simple-table>
                                            </template>

                                            <template v-if="showDatos === 'PTU'">
                                                <table class="elevation-0 mt-4">
                                                    <thead class="tbl-header-reconsulta">
                                                        <tr>
                                                            <th class="th-reconsulta">
                                                                Empleados
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template v-for="(item,index) in itemsEmpleados">
                                                            <ExpandableItemEmpleado
                                                                :item="item"
                                                                :key="index" 
                                                            >
                                                            </ExpandableItemEmpleado>
                                                        </template>
                                                    </tbody>
                                                </table>
                                            </template>
                                        </v-row>  
                                    </v-container>
                                </v-card-text>
                            </perfect-scrollbar>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModalTabla()">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogTimbre" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img :src="imagen" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        ¿Estás seguro que deseas acumular y timbrar el cálculo de nómina? No se podrá realizar ningún movimiento posteriormente.
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('acumular')"
                                        >
                                            Acumular
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="acumular('timbrar')"
                                        >
                                            Acumular y timbrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="cerrarModal()"
                                            text
                                        >
                                            Cancelar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>



            <v-dialog v-model="dialogAF" persistent max-width="600px" scrollable class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalAF" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                    <perfect-scrollbar style="background:#FFF">
                        <ValidationObserver ref="formAF" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <template v-if="obtenerCodigo.tipo_periodo_especial == 'AGUINALDO'">
                                        <h2 class="titleModal">{{ 'Actualizar Aguinaldo' }}</h2>
                                    </template>
                                    <template v-else-if="obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'">
                                        <h2 class="titleModal">{{ 'Actualizar Finiquito' }}</h2>
                                    </template>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">

                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        
                                        
                                        <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'AGUINALDO'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-container fluid class="py-0">
                                                <v-checkbox class="pa-0 ma-0" v-model="aguinaldoFiniquito.descontar_faltas" label="Descontar faltas"></v-checkbox>
                                                <v-checkbox class="pa-0 ma-0" v-model="aguinaldoFiniquito.descontar_incapacidades" label="Descontar incapacidades"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-container fluid class="py-0">
                                                <v-radio-group row v-model="opcionFiniquito" class="py-0">
                                                    <v-radio
                                                        label="Voluntario"
                                                        value="VOLUNTARIO"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Despido"
                                                        value="DESPIDO"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-container>
                                            <v-container v-if="opcionFiniquito == 'DESPIDO'" fluid class="py-0">
                                                <v-radio-group row v-model="opcionDespido" class="py-0">
                                                    <v-radio
                                                        label="Completa (90 días)"
                                                        value="COMPLETA"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="Negociación"
                                                        value="NEGOCIACION"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-container>
                                            <v-container v-if="opcionFiniquito == 'DESPIDO' && opcionDespido == 'NEGOCIACION'" fluid>
                                                <v-radio-group v-model="opcionDias" class="py-0">
                                                    <v-radio
                                                        label="60 días"
                                                        value="60"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="45 días"
                                                        value="45"
                                                    ></v-radio>
                                                    <div class="d-flex">
                                                        <v-col cols="12" xs="3" sm="3" md="3" class="pa-0">
                                                            <v-radio
                                                                label="Otro"
                                                                value="otro"
                                                            ></v-radio>
                                                        </v-col>
                                                        <v-col cols="12" sx="" sm="3" md="3" class="pa-0" > 
                                                            <input type="text" v-mask="'###'" v-model="diasOtro" style="border-bottom: 2px solid #a6a6a6;">
                                                        </v-col>
                                                    </div>
                                                </v-radio-group>
                                            </v-container>
                                            <v-container fluid class="py-0">
                                                <div class="py-2 textAguinaldo">Otras acciones</div>
                                                <v-checkbox v-if="opcionFiniquito == 'DESPIDO'" class="pa-0 ma-0 checkboxVuetify" v-model="agregar_indemnizacion_20_dias" label="Indemnización de 20 días por año"></v-checkbox>
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="agregar_sueldos" label="Agregar sueldos"></v-checkbox>
                                                
                                                <div class="py-2 textAguinaldo">Aguinaldo</div>
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="aguinaldoFiniquito.descontar_faltas" label="Descontar faltas"></v-checkbox>
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="aguinaldoFiniquito.descontar_incapacidades" label="Descontar incapacidades"></v-checkbox>
                                            </v-container>
                                        </v-col>
                                        <v-col v-if="agregar_sueldos" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                :valorDefecto="aguinaldoFiniquito.fecha_inicial"
                                                v-model="aguinaldoFiniquito.fecha_inicial"
                                                label="Fecha inicial"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaInicial"
                                            />
                                        </v-col>
                                        <v-col v-if="agregar_sueldos" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                :valorDefecto="aguinaldoFiniquito.fecha_final"
                                                v-model="aguinaldoFiniquito.fecha_final"
                                                label="Fecha final"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaFinal"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Código" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Código" 
                                                    v-model="aguinaldoFiniquito.numero" 
                                                    :error-messages="errors" 
                                                    required
                                                    readonly
                                                    v-mask="'N########'"
                                                >    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Mes de Acumulación" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Mes de Acumulación"
                                                    :items="meses"
                                                    item-text="nombre"
                                                    item-value="nombre"
                                                    persistent-hint
                                                    v-model="aguinaldoFiniquito.mes_de_acomulacion"
                                                    required 
                                                    :error-messages="errors" >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Descripcion" rules="required">
                                                <v-text-field 
                                                    outlined 
                                                    label="Descripción" 
                                                    v-model="aguinaldoFiniquito.descripcion" 
                                                    :error-messages="errors" 
                                                    required
                                                >                                                    
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                :valorDefecto="aguinaldoFiniquito.fecha_de_pago"
                                                v-model="aguinaldoFiniquito.fecha_de_pago"
                                                label="Fecha de pago"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaPago"
                                            />
                                        </v-col>
                                        <v-col v-if="obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                :valorDefecto="aguinaldoFiniquito.fecha_baja"
                                                v-model="aguinaldoFiniquito.fecha_baja"
                                                label="Fecha de baja"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaBaja"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarAF()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </perfect-scrollbar>
                </v-card>
            </v-dialog>



            <v-dialog v-model="dialogPTU" persistent max-width="600px" scrollable class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalPTU" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                    <perfect-scrollbar style="background:#FFF">
                        <ValidationObserver ref="formPTU" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ 'Actualizar PTU' }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">

                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Año fiscal" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Año fiscal"
                                                    :items="itemsAnios"
                                                    item-text="anio.nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="itemPTU.anio_fiscal_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6" class="py-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Tope de ingreso"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="itemPTU.tope_ingreso_anual"
                                                v-bind:options="options"
                                                v-bind:showAppendOuter="true"
                                                v-bind:textTooltip="'El valor ingresado será el tope para el monto de los <br>salarios devengados durante el ejercicio, que será el <br>20% de mas del salario de un trabajador sindicalizado <br>o de planta. Fundamentado Artículo 127 Fracción II LFT.'"
                                                ref="topeIngreso"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6" class="py-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Importe a repartir"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="itemPTU.importe_repartir"
                                                v-bind:options="options"
                                                ref="importeRepartir"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha de pago"
                                                :valorDefecto="itemPTU.fecha_de_pago"
                                                v-model="itemPTU.fecha_de_pago"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaPagoPTU"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha inicial"
                                                :valorDefecto="itemPTU.fecha_inicial"
                                                v-model="itemPTU.fecha_inicial"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaInicialPTU"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                label="Fecha final"
                                                :valorDefecto="itemPTU.fecha_final"
                                                v-model="itemPTU.fecha_final"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaFinalPTU"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Mes de acumulación" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Mes de acumulación"
                                                    :items="meses"
                                                    item-text="nombre"
                                                    item-value="nombre"
                                                    persistent-hint
                                                    v-model="itemPTU.mes_de_acomulacion"
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>    

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Complemento de PTU" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Complemento de PTU"
                                                    :items="itemsComplementos"
                                                    item-text="nombre"
                                                    item-value="value"
                                                    persistent-hint
                                                    v-model="itemPTU.tipo_complemento"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <v-divider></v-divider>
                                            <p class="mt-3 subtitle-divider">Personal que no participara en el reparto de utilidades</p>
                                        </v-col>
                                                
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                :items="itemsPuestos"
                                                :valorDefecto="puestosExcluidos"
                                                item_text="nombre"
                                                @dataMultiple="(data)=> puestosExcluidos = data"
                                                label="Puestos"
                                                ref="multPuestos"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex align-center text-center text-laborados">
                                            <p class="mb-0">Trabajadores con menos de </p>
                                            <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    v-model="itemPTU.dias_minimo_laborados"
                                                    v-mask="'####'"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <p class="mb-0"> días laborados</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                            <v-container fluid class="py-0 d-flex">
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="itemPTU.solo_acumulados" label="Calcular únicamente empleados acumulados"></v-checkbox>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        En caso de no seleccionar esta opción, solo se <br>
                                                        calcularán los empleados timbrados.
                                                    </span>
                                                </v-tooltip>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                            <v-container fluid class="py-0 d-flex">
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="itemPTU.incluir_vacaciones_pagadas" label="No incluir vacaciones pagadas"></v-checkbox>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        En caso de no seleccionar esta opción, el sistema <br>
                                                        incluirá las vacaciones pagadas en el monto de los <br>
                                                        ingresos percibidos durante el año del cálculo de <br>
                                                        Reparto de utilidades.
                                                    </span>
                                                </v-tooltip>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                            <v-container fluid class="py-0 d-flex">
                                                <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="itemPTU.topar_ptu" label="Topar PTU"></v-checkbox>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        Configuración de tope de PTU a 3 meses de salario o <br>
                                                        promedio de PTU recibida los últimos 3 años.
                                                    </span>
                                                </v-tooltip>
                                            </v-container>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarPTU()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </perfect-scrollbar>
                </v-card>
            </v-dialog>

            <v-dialog v-model="modalEmpleadosTimbre" persistent max-width="800px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalTimbrarAcumulados" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="formAcumularTimbrar" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img src="/static/modal/nominaTimbrar.png" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingModalEmpleados">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModalEmpleados" grid-list-md id="contenedor">
                                

                                <table class="tbl-modal">
                                    <thead class="tbl-header-modal">
                                        <tr>
                                            <th  class="pl-7 firstth">Empleado</th>
                                            <th>Fecha pago</th>
                                            <th class="lastth">Tipo retimbre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="datosTabla.length == 0" >
                                            <td class="emptyTableModal" colspan="11">
                                                No hay elementos para mostrar
                                            </td>
                                        </tr>
                                        <template v-else v-for="(empleado, index) in datosTabla">
                                            <tr :key="index">
                                                <td class="pl-5">
                                                    <span>
                                                        {{empleado.empleado}}
                                                    </span>
                                                    <v-tooltip bottom v-if="empleado.cancelado">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                color="red"
                                                                dark
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                block
                                                            </v-icon>
                                                        </template>
                                                        <span>Empleado cancelado</span>
                                                    </v-tooltip>
                                                </td>
                                                <td>
                                                    <VuetifyDatePicker
                                                        outlined
                                                        rules="required"
                                                        :valorDefecto="empleado.fecha_pago"
                                                        v-model="empleado.fecha_pago"
                                                        label=""
                                                        placeholder="dd/mm/aaaa"
                                                        dense
                                                        hideDetails
                                                        :disabled="empleado.retimbrado"
                                                    />
                                                </td>
                                                <td class="pr-5 py-2">
                                                    <v-autocomplete
                                                        v-if="empleado.tipo_retimbrar != null"
                                                        outlined
                                                        label=""
                                                        :items="itemsTiposRetimbre"
                                                        item-text="nombre"
                                                        item-value="clave"
                                                        persistent-hint
                                                        v-model="empleado.tipo_retimbrar"
                                                        class="pa-0 ma-0"
                                                        dense
                                                        hideDetails
                                                        :disabled="empleado.cancelado"
                                                    >
                                                    </v-autocomplete>
                                                </td>

                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                v-if="datosTabla.length==0"
                                class="btnNormal ml-2"
                                disabled
                            >
                                Acumular y timbrar
                            </v-btn>
                            <v-btn
                                v-else
                                class="btnNormal ml-2"
                                @click="acumularTimbrar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Acumular y timbrar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogMensaje" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalTimbre" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                        <v-card-title class="justify-center align-center text-center card_titulo">
                            <div>
                                <img :src="'/static/icon/alert_success.png'" class="imgModal pb-5" />
                                <h2 class="titleModalTimbre pb-5">Proceso de timbre terminado</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container  grid-list-md id="contenedor">
                                <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                    El proceso de timbre terminó satisfactoriamente.
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 justify-center align-center text-center textTimbre">
                                    Procesados: {{procesados}} <br>
                                    Con error: {{conError}}
                                </v-col>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="card_accion pt-0">
                            <v-container  grid-list-md class="ma-0 pa-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                    <v-btn 
                                        class="btnModalAlert"
                                        color='#1E2245'
                                        dark
                                        @click="cerrarModalMensaje()"
                                    >
                                        ok
                                    </v-btn>
                                </v-col>
                            </v-container>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';

/* API's */
import apiClientes from "@/api/clientes";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiEmpresas from '@/api/empresas'
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import apiPTU from "@/api/nominas/ptu";
import apiPuestos from "@/api/nominas/puestos";

/* Componentes */
import Datatable from '@/components/datatable/Datatable';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItemEmpleado from '@/components/ExpandableItemPTUEmpleado.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

/* Plugins */
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import idseApi from '@/api/idse';

import env from "@/plugins/enviroment";
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');

export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
        VuetifyDatePicker,
        ExpandableItem,
        ExpandableItemEmpleado,
        VuetifyMoneyValidate,
        VuetifyMultipleAutocomplete
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            urlAvatar       : null,
            url             : "calculo-nomina/listado",
            columns         : [
                {
                    label: 'Tipo nómina',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'numero',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                empresa_id: null,
                acumulado: true,
                desacumulado: true,
                tipo_nomina: 'GENERAL'
            },
            filtersFA         : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                empresa_id: null,
                acumulado: true,
                desacumulado: true,
                tipo_nomina: 'AGUINALDO'
            },
            filtersPTU       : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                empresa_id: null,
                acumulado: true,
                desacumulado: true,
                tipo_nomina: 'PTU'
            },
            calculoNom                      : {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : '',
            items_empleados         : [],
            modelo_empleados        : [],
            itemsTiposNominas       : [],
            tipo_nomina_id          : null,
            itemsPeriodos           : [],
            calendar_pago           : false,
            incidencias_reloj_checador  : "NO",
            incidencias_capturadas      : "NO",
            modificaciones_adicionales  : "NO",
            empresa_id              : null,
            itemsEmpresas           : [],
            comision                : 0,
            iva                     : 0,
            retencion_iva           : "NO",
            retencion_isr           : "NO",
            itemsClientes           : [],
            cliente_value           : null,
            empresa_value           : null,
            periodo_value           : null,
            fecha_inicio          : null,
            fecha_final           : null,
            fechaPago               : null,
            cambio_empleado         : false,
            imagenAvatar            : "/static/avatar/usuario.jpg",
            showGuardar             : true,
            isLoadingCalculo        : true,
            dialogCalculo           : false,
            imagenCalculo           : '',
            tituloModalCalculo      : 'Cálculo de nómina',
            itemCalculo             : null,
            dialogDownload          : false,
            imagenDownload          : "/static/icon/catalogos.png",
            tituloModalDownload     : "Descargando archivo",
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
            estatus_value           : null,
            itemsEstatus            : [
                { id:1, nombre:"Acumulado", valor:"ACUMULADO" },
                { id:1, nombre:"Cancelado", valor:"CANCELADO" },
                { id:1, nombre:"Incompleta", valor:"INCOMPLETO" },
                { id:1, nombre:"Timbrado", valor:"TIMBRADO" }
            ],
            dialogCancelar : false,
            modalTitle : '',
            datos : null,
            claveMotivo: '02',
            menuPeriodo: false,
            dialogErrores: false,
            erroresRP: [],
            itemsEmpleados          : [],
            headersTableEmpleados   : [
                { label: "Empleados", align:'center',   name: "nombre_completo",    filterable: false },
            ],

            isLoadingCalculoTabla   : true,
            dialogCalculoTabla      : false,
            imagenCalculoTabla      : '/static/icon/consultar-calculo.png',
            tituloModalCalculoTabla : 'Resultados de la consulta',

            invalidBtnConsultar     : false,
            invaliBtnGuardar        : false,
            btnConsultar            : false,
            btnGuardar              : false,
            btnCerrar               : false,
            accionCalculo           : 'CALCULAR',
            updatePeriodo           : false,
            dialogTimbre            : false,
            itemPrecalculo          : null,
            totalPercepciones       : 0,
            totalDeducciones        : 0,
            totalOtrosPagos         : 0,
            totalEmpleados          : 0,
            nombreEmpresa           : null,
            periodicidad            : null,
            periodoFechaInicial     : null,
            periodoFechaFinal       : null,
            neto                    : null,

            itemFiniquito           : null,
            showDatos               : null,
            mesAcumulacion          : null,
            periodoFechaPago        : null,

            tab: 0,

            dialogAF    : false,
            meses:[ 
                {nombre:'Enero',     id:0},
                {nombre:'Febrero',   id:1}, 
                {nombre:'Marzo',     id:2}, 
                {nombre:'Abril',     id:3}, 
                {nombre:'Mayo',      id:4}, 
                {nombre:'Junio',     id:5},
                {nombre:'Julio',     id:6},
                {nombre:'Agosto',    id:7}, 
                {nombre:'Septiembre',id:8}, 
                {nombre:'Octubre',   id:9}, 
                {nombre:'Noviembre', id:10}, 
                {nombre:'Diciembre', id:11}
            ], 
            aguinaldoFiniquito  : {
                id                      : null,
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                fecha_inicial           : '',
                dias_de_pago            : null,
                fecha_final             : '',
                especial                : true,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : 'AGUINALDO',
                descontar_faltas        : false,
                descontar_incapacidades : false,

                tipo_separacion         : null,
                tipo_liquidacion        : null,
                dias_finiquito          : null,
                fecha_baja              : '',
                nomina_id               :0,
            },
            obtenerCodigo       : {
                tipo_nomina_id          : null,
                tipo_periodo_especial   : 'AGUINALDO'
            },
            opcionFiniquito             : "VOLUNTARIO",
            opcionDespido               : "COMPLETA",
            opcionDias                  : "60",
            diasOtro                    : null,
            agregar_indemnizacion_20_dias : false,
            agregar_sueldos             : false,
            tamModal                    : "700px",

            dialogPTU: false,
            options: {
                length: 11,
                locale: "es-MX",
                precision: 2,
                prefix: "$",
                suffix: "",
            },
            itemsAnios              : [],
            itemsPuestos            : [],
            itemsComplementos: [
                {nombre: "PTU" , value: "p_PTU" }, 
                {nombre: "PTU SMG" , value: "p_PTU_SMG"}
            ],
            itemPTU : {
                anio_fiscal_id              : null,
                tope_ingreso_anual          : null,
                importe_repartir            : null,
                fecha_de_pago               : null,
                fecha_inicial               : null,
                fecha_final                 : null,
                mes_de_acomulacion          : null,
                tipo_complemento            : null,
                puestos_excluidos           : [],
                dias_minimo_laborados       : null,
                solo_acumulados             : false,
                incluir_vacaciones_pagadas  : false,
                topar_ptu                   : false,

                id                          : null,
                dias_de_pago                : null,
                empleados_menor_60_dias     : true
            },
            meses:[ 
                {nombre:'Enero',     id:0},
                {nombre:'Febrero',   id:1}, 
                {nombre:'Marzo',     id:2}, 
                {nombre:'Abril',     id:3}, 
                {nombre:'Mayo',      id:4}, 
                {nombre:'Junio',     id:5},
                {nombre:'Julio',     id:6},
                {nombre:'Agosto',    id:7}, 
                {nombre:'Septiembre',id:8}, 
                {nombre:'Octubre',   id:9}, 
                {nombre:'Noviembre', id:10}, 
                {nombre:'Diciembre', id:11}
            ],
            puestosExcluidos    : [],

            modalEmpleadosTimbre    : false,
            isLoadingModalEmpleados : false,
            datosTabla              : [],
            itemsTiposRetimbre      : [
                {
                    nombre:"Clave: 01: Comprobante emitido con errores con relación.",
                    clave: "01",
                },
                {
                    nombre:"Clave: 02: Comprobante emitido con errores sin relación.",
                    clave: "02",
                },
                {
                    nombre:"Clave 03: No se llevó a cabo la operación",
                    clave: "03",
                },
            ],

            nominaPorcentaje        : 0,
            nominaTotal             : 0,
            nominaProcesados        : 0,
            dialogMensaje           : false,
            procesados              : 0,
            conError                : 0,
            mostrarProceso          : false,

        }
    },
    watch: {
        cliente_value: function(val){
            if(val!= null){
               this.getEmpresas(val);
            }
        },
        empresa_value: function(val){
            if(val != null){
                this.getTiposNominas(); 
            }
        },
        tipo_nomina_id: function(val){
            if(val != null){
                this.getPeriodos(val);
                //this.getEmpleados(val);
            } 
        },
        modificaciones_adicionales: function(val){
            if(val == 'NO'){
                document.getElementById("tipoNominaID").focus();
            }
        },
        opcionDias: function(val){
            if(val!= null){
               if(val != "otro"){
                   this.diasOtro = null;
               }
            }
        },
        opcionFiniquito: function(val){
            if(val == 'VOLUNTARIO'){
               this.agregar_indemnizacion_20_dias = false;
            }
        },
        'obtenerCodigo.tipo_periodo_especial': function(val){
            if(val == 'AGUINALDO'){
                this.agregar_sueldos = false;
                this.aguinaldoFiniquito.fecha_inicial = null;
                this.aguinaldoFiniquito.fecha_final = null;
                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                    this.$refs.FechaFinal.resetDate();
                }
            }
        },
        modelo_empleados: function (val) {
            console.log(val)
        }
    },
    methods: {
        cerrarModal() {
            this.dialog         = false;
            this.isSaving       = false;
            this.dialogCalculo  = false;
            this.dialogCancelar = false;
            this.dialogTimbre   = false;
            this.dialogAF       = false;
            this.dialogPTU      = false;
            this.claveMotivo    = '02'
            this.modalEmpleadosTimbre = false;
            this.resetValues();
        },
        resetValues() {
            this.calculoNom                 = {
                id                          : null,
                tipo_nomina_id              : null,
                periodo_id                  : null,
                incidencias_checador        : false,
                incidencias_capturadas      : false,
                modificaciones_adicionales  : false,
                acumulado                   : false,
                modificaciones_campos       : {
                    empresa_id              : null,
                    comision                : 0,
                    iva                     : 0,
                    aplica_retencion_iva    : false,
                    aplica_retencion_isr    : false,
                },
            },
            this.incidencias_reloj_checador  = "NO";
            this.incidencias_capturadas      = "NO";
            this.modificaciones_adicionales  = "NO";

            this.empresa_id         = null;
            this.cliente_value      = null;
            this.empresa_value      = null;
            this.itemsEmpresas      = [];
            this.comision           = 0;
            this.iva                = 0;
            this.retencion_iva      = "NO";
            this.retencion_isr      = "NO"; 
            this.fechaPago          = null;
            this.itemsClientes      = [];
            this.tipo_nomina_id     = null;
            this.items_empleados    = [];
            this.modelo_empleados   = [];
            this.itemsTiposNominas  = [];
            this.itemsPeriodos      = [];
            this.showGuardar        = true;
            this.estatus_value      = null;

            this.accionCalculo      = 'CALCULAR';
            this.itemPrecalculo     = null;

            this.itemsClientes = [];
            this.empresa_value = null;
            this.periodo_value = null;
            this.fecha_inicio = null;
            this.fecha_final  = null; 

            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }



            this.aguinaldoFiniquito  = {
                id                      : null,
                tipo_nomina_id          : null,
                periodicidad_id         : null,
                tipo_nomina_SAT_id      : null,
                numero                  : null,
                fecha_inicial           : '',
                dias_de_pago            : null,
                fecha_final             : '',
                especial                : true,
                inicio_de_anio          : false,
                fin_de_anio             : false,
                inicio_de_bimestre      : false,
                fin_de_bimestre         : false,
                inicio_de_mes           : false,
                fin_de_mes              : false,
                mes_de_acomulacion      : '',
                descripcion             : '',
                fecha_de_pago           : '',
                numero_de_factura       : '',
                tipo_periodo_especial   : 'AGUINALDO',
                descontar_faltas        : false,
                descontar_incapacidades : false,

                tipo_separacion         : null,
                tipo_liquidacion        : null,
                dias_finiquito          : null,
                fecha_baja              : '',
                nomina_id               :0,
            };
            this.obtenerCodigo       = {
                tipo_nomina_id          : null,
                tipo_periodo_especial   : 'AGUINALDO'
            };
            this.opcionFiniquito                = "VOLUNTARIO";
            this.opcionDespido                  = "COMPLETA";
            this.opcionDias                     = "60";
            this.diasOtro                       = null;
            this.agregar_indemnizacion_20_dias  = false;
            this.agregar_sueldos                = false;

            this.isLoadingModalEmpleados    = false;
            this.datosTabla                 = [];


            this.cambio_empleado = false;
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },

        /**
         * @method acumular Este método realiza la acción de acumular el cálculo de nómina del periodo.
         * @description Este método realiza la acción de acumular el cálculo de nómina del periodo. Se envia un parametro "accion" que puede tomar el valor de 'acumular' o 'timbrar'.
         * -Si la accion='acumular' se realiza la petición de acumular y se actuliza la tabla en caso de que la petición sea correcta.
         * -Si la accion='timbrar' se realiza la petición de acumular y en caso de que la petición sea correcta se realiza la petición del timbrado de nómina, seguido se actualiza la tabla. 
         * @param {string} accion Esta variable es un string, le valor puede ser 'acumular' o 'timbrar'.
        */
        async acumular(accion){
            let self = this;
            if (accion == 'acumular'){
                this.loadingLottie = true;
                this.fraseLoading = "Acumulando";
                let param = {nomina_id:self.itemPrecalculo.id};
                apiCalculoNomina.acumular(param).then(async response => {
                    Notify.Success("Acumulado de nómina", "El cálculo de nómina ha sido acumulado correctamente."); 
                    this.loadingLottie = false;
                    self.$refs.tabla1.getData();
                    self.cerrarModal();
                })
                .catch(err => {
                    this.loadingLottie = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        self.cerrarModal();
                    }
                });
            }
            else if (accion == 'timbrar'){
                this.modalEmpleadosTimbre = true;
                this.isLoadingModalEmpleados = true;
                this.dialogTimbre = false;
                this.tituloModal = "Acumular y timbrar"

                apiCalculoNomina.getEmpleadosAcumularTimbrar(this.itemPrecalculo.id).then(async response => {
                    let format = response.data
                    this.datosTabla = await format.map(empleado => {
                        empleado.tipo_retimbrar = null;
                        if(empleado.retimbrar == true) {
                            if(empleado.cancelado){
                                empleado.tipo_retimbrar = empleado.clave
                            }else{
                                empleado.tipo_retimbrar = '01';
                            }
                        }
                        return empleado
                    })
                    this.isLoadingModalEmpleados = false;
                })
                .catch(err => {
                    this.isLoadingModalEmpleados = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
                
            }
        },

        acumularTimbrar(){
            let formatDatos = this.datosTabla.map(empleado => {
                if(empleado.cancelado == true){
                    empleado.tipo_retimbrar = null
                }
                return empleado;
            } )
            let param = {
                data: formatDatos,
                acumulado:true
            }
            this.socketProcesandoNomina(this.itemPrecalculo)

            this.isSaving = true;
            apiCalculoNomina.timbraRetimbrarDesacumulados(param).then(response => {
                this.isSaving = false;
                if(response.status === 202){
                    if(typeof response.data.message === 'string'){
                        Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                    }else{
                        let error = Object.values(response.data.message);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        leyenda += " Favor de verificar el detalle";
                        Notify.ErrorToast(leyenda);
                    }

                }
                if(response.status === 200){
                    Notify.Success("Timbrado", "El registro se envío a timbrar");
                }
                this.modalEmpleadosTimbre = false;
            })
            .catch(err => {
                this.modalEmpleadosTimbre = false;
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
       
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        setFilters() {
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id;
            let periodo_value = this.periodo_value;
            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let estatus     = this.estatus_value;
            

            if (this.rol !== "root") {
                cliente = this.datosLogin.cliente_id;
                if (this.rol !== "admin") {
                    empresa = this.datosLogin.empresa_id;
                } 
            } 

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                tipo_nomina_id: tipo_nomina,
                periodo_id: periodo_value,
                min_fecha_inicio: fecha_inicio,
                max_fecha_final: fecha_final,
                estatus:estatus
            };

            let defaultParams = {
                activo    :true,
                paginate  :true,
                order     :"desc",
                acumulado   : true,
                desacumulado: true,
                tipo_nomina : "GENERAL" 
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            if(this.tab == 0){
                defaultParams.tipo_nomina = "GENERAL"
                this.filters = defaultParams;
            }
            else if(this.tab == 1){
                defaultParams.tipo_nomina = "AGUINALDO"
                this.filtersAF = defaultParams;
            }
            else if(this.tab == 2){
                defaultParams.tipo_nomina = "PTU"
                this.filtersPTU = defaultParams;
            }

            this.cliente_value = null;
            this.itemsEmpresas = [];
            this.empresa_value = null;
            this.itemsTiposNominas = [];
            this.tipo_nomina_id = null;
            this.itemsPeriodos = [];
            this.periodo_value = null;
            this.estatus_value = null;
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },

        /* #region LEONARDO */
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        /* endregion */
        async getPeriodos(tipoNomID){
            
            let param = {
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID,
                acumulado   : true
            }
            await apiPeriodos.find(param).then((response) => {
                this.itemsPeriodos = response.data;
                if(this.itemsPeriodos.length != 0){
                    if(this.accion === "add") {
                        let per = this.itemsPeriodos.find( elemento => elemento.actual == true )
                        this.calculoNom.periodo_id = per.id;
                        this.fechaPago = this.dateFormat(per.fecha_de_pago, 'modal');
                    }                    
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los periodos");
            })
        },
        /**
         * @method getEmpleados Este método realiza la petición a la api de empleados.
         * @description Este método realiza la petición a la api de empleados que va servir en el modal para agregar los empleados que se quieren cálcular la nómina.
         * @param {Object} datos Objeto que contiene los datos del requistro de cálculo de nómina.
         */
         async getEmpleados(datos) {
            let param = {
                tipo_nomina_id:datos.tipo_nomina_id, 
                periodo_id:datos.periodo_id, 
                tipo:"GENERAL",
                desacumulado: true,
                filtro: "calculo"
            }
            await apiTipoNomina.getEmpleados(param).then((response) => {
                this.items_empleados = response.data;
                if(response.data.length == 0){
                    this.invaliBtnGuardar = true;
                    this.invalidBtnConsultar = true;
                }
                else{
                    this.invaliBtnGuardar = false;
                    this.invalidBtnConsultar = false;
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los empleados.");
            })
        },
        remove (item) {
            const index = this.modelo_empleados.indexOf(item.id)
            if (index >= 0) this.modelo_empleados.splice(index, 1)
        },
        cambio_emp(){
            this.cambio_empleado = true;
        },
        async getEmpresas(cliente_id){
            let param = {
                activo      : true, 
                paginate    : false,
                cliente_id  : cliente_id
            }
            await apiEmpresas.getEmpresas(param).then((response) => {
                this.itemsEmpresas = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar las empresas.");
            })
        },

        /**
         * @method descargarReporte Este método descarga un reporte con extensión .xlsx
         * @description Este método realiza la petición a la api calcular y descargamos el reporte con extensión .xlsx
         * @param {Object} item Objeto que contiene los datos del registro del cálculo de nómina.
         * @param {String} tipo tipo de consulta 'reporte' o 'calcular'
         * @param {accion} accion tipo de accion en este caso es RECALCULAR o CALCULAR.
         */
         async descargarReporte(item, tipo, accion){
            let sefl = this;
            this.btnGuardar = true;
            this.invalidBtnConsultar = true;
            this.btnCerrar = true;

            let url = "calculo-nomina/calcular";
            
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            nombre =  item.clave_tipo_nomina + "-" + item.descripcion + " - Periodo " + item.numero_periodo + ".xlsx";
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });
            let itemCalculoId = null;
            let descargar = false;
            if(tipo == 'reporte'){
                itemCalculoId = item.id;
                descargar = true;
                this.loadingLottie =  true;
                this.fraseLoading = "Descargando";
            }
            else{
                itemCalculoId = this.itemCalculo.id;
                descargar = false;
            }

            let parametros = { id : itemCalculoId, accion: accion, tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : empleados, descargar:descargar }
            

            if(item.tipo_periodo_especial == 'AGUINALDO'){
                url = "calculo-nomina/calcular-aguinaldo"
                parametros = {
                    "accion":"RECALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.periodo_id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": empleados,
                    "descargar":true
                }
            }
            else if(item.tipo_periodo_especial == 'FINIQUITO'){
                url = "calculo-nomina/calcular-finiquito"
                parametros = {
                    "accion":"RECALCULAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": item.tipo_periodo_especial,
                    "periodo_id": item.periodo_id,
                    "tipo_nomina_id": item.tipo_nomina_id,
                    "empleados": empleados,
                    "descargar":true
                }
            }


            const object1 = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object1,
                }).then(function(response){
                    FileDownload(response.data, nombre);
                    sefl.$refs.tabla1.getData();
                    sefl.btnGuardar = false;
                    sefl.invalidBtnConsultar = false;
                    sefl.btnCerrar = false;
                    sefl.loadingLottie =  false;
                    sefl.cerrarModal();
                })
                .catch(async (error) => {
                    //console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                    //Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));

                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }

                    this.btnGuardar = false;
                    this.invalidBtnConsultar = false;
                    this.btnCerrar = false;
                    this.loadingLottie =  false;
                    this.cerrarModal();
                });
            } catch (err) {
                Notify.ErrorToast("La configuración de la nómina tiene agregar incidencias, pero aun hay periodos de incidencias no cerradas.");
                this.btnGuardar = false;
                this.invalidBtnConsultar = false;
                this.btnCerrar = false;
                this.loadingLottie =  false;
                this.cerrarModal();
            }
        },
        async descargarReporteEmpleados(item, tipo){
            let url             = "reportes/nomina";
            this.loadingLottie  =  true;
            this.fraseLoading   = "Descargando";
            const FileDownload  = require("js-file-download");
            let nombre          = item.nombre_empresa + "-" + item.clave_tipo_nomina + "-" + item.numero_periodo + ".xlsx";
            let parametros = { nomina_id : item.id, tipo_nomina : 'GENERAL' }
            const object1 = parametros;

            try {
                await Vue.axios({
                method: "POST",
                responseType: "blob",
                url: url,
                data: object1,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                });
            } catch (err) {
                this.loadingLottie = false;
                Notify.ErrorToast("No se pudo calcular la nomina");
            }
        },
        reporteDeEmpleados(item){
            let sefl = this;
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            
            let parametros = {
                nomina_id : item.id,
                tipo_nomina: item.tipo_periodo_especial != null ? item.tipo_periodo_especial: 'GENERAL',
                tipo:"DESACUMULADO"
            }

            let nombre;
            nombre = item.nombre_empresa + "-" + item.clave_tipo_nomina + "-" + item.numero_periodo + ".xlsx";

            let urlCalculo = "reportes/nomina";

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async error =>{
                    //console.log(err);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        dateFormat(fecha, tipo) {
            if(!fecha) return
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        timbrarNomina(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas timbrar el cálculo de nómina " + data.clave_tipo_nomina + " - " + data.descripcion + "?",
                "Timbrar",
                () => {
                    /* this.fraseLoading = "Timbrando";
                    this.loadingLottie = true; */
                    let param = {nomina_id:data.id};
                    apiCalculoNomina.timbrar(param).then(response => {
                        //this.detalleTimbre(data);

                        if(response.status == 200){
                            this.detalleTimbre(data);
                        }
                        else if(response.status == 202){
                            this.erroresRP = response.data.items;
                            this.dialogErrores = true;
                        }
                    })
                    .catch(err => {
                        /* this.loadingLottie=false;  */
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                        return;
                    });

                }
            )
        },
        abrirModalCancelar(data){
            this.dialogCancelar = true;
            this.modalTitle = 'Seleccionar motivo de cancelación';
            this.datos = data;
        },
        cancelarTimbreNomina(){
            Notify.Alert(
                "¿Estás seguro que deseas cancelar el timbre de este cálculo de nómina?",
                "",
                "Cancelar timbrado",
                () => {
                    this.fraseLoading = "Cancelando";
                    this.loadingLottie = true;
                    let param = {
                        clave_motivo:this.claveMotivo,
                        nomina_id:this.datos.id
                    };
                    apiCalculoNomina.cancelar(param).then(response => {
                        this.loadingLottie=false; 
                        
                        if(response.status === 200){
                            Notify.Success("Cancelado de nómina", "El cálculo de nómina ha sido cancelado correctamente.");
                        }
                        else if(response.status == 202){
                            this.erroresRP = response.data.items;
                            this.dialogErrores = true;
                        }
                        this.cerrarModal();
                        this.$refs.tabla1.getData();
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.cerrarModal();
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        detalleTimbre(data){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',data);
            this.$router.push('/detalleTimbre');
        },
        textColor(estatus){
             switch (estatus) {
                case "Incompleta":
                    return "#F86868";
                    break;
                case "Cancelado":
                    return "#F86868";
                    break;
                case "Acumulado":
                    return "#FF9B05";
                    break;
                case "Timbrado":
                    return "#86EF83";
                    break;
                case undefined:
                    return "";
                    break;
                default:
                    return "#BDBDBD";
                    break;
            }
        },
        detalleAcumulado(data){
            this.$session.remove("detalleDesacumulado");
            this.$session.set('detalleDesacumulado',data);
            this.$router.push('/detalleDesacumulado');
        },
        async desacumular(data){
            let param = {};
            param = { 
                nomina_id:data.id, 
                empleados:data.empleados,
                todos: true
            };
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas realizar el proceso de desacumulación?",
                "Desacumular",
                () => {
                    apiCalculoNomina.desacumular(param).then(response => {
                        Notify.Success("Operación exitosa", "La desacumulación se ha realizado satisfactoriamente.");
                        this.$refs.tabla1.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        listaRaya(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                nomina_id: item.id
            }

            let nombre;
            nombre = "Lista_de_raya_"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".pdf";

            let urlCalculo = "reportes/lista-raya";

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(err =>{
                    console.log(err);
                    this.loadingLottie = false;
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        actualizarTabla(tabla){
            if(tabla == 'tabla1'){
                this.$refs.tabla1.getData();
            }
            else if(tabla == 'tabla2'){
                this.$refs.tabla2.getData();
            }
            else if(tabla ==  'tabla3'){    
                this.$refs.tabla3.getData();
            }
        },

        odessaConfirmacion(item){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = {
                periodo_id: item.periodo_id
            }
            let nombre;
            nombre = "ODESSA_confirmacion"+item.nombre_empresa + "_" + item.clave_tipo_nomina + "_" + item.numero_periodo + ".zip";

            let urlCalculo = "odesa/confirmacion";

            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: urlCalculo,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    console.log(error);
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                    
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la descarga");
                console.log(err);
                this.loadingLottie = false;
            }
        },

        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        },
        vistaComplementos(item){
            this.$session.remove("nominaDesacumulada");
            this.$session.set('nominaDesacumulada',item);
            this.$router.push('/desacumuladosComplementos');
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },

        /**
         * @method abrirModalCalcular Método para abrir el modal para calcular y precalcular (consultar)
         * @description Método para abrir el modal para calcular y precalcular (consultar).
         */
        async abrirModalCalcular(datos, tipoCalculo){
            this.isLoadingCalculo   = true;
            this.accionCalculo = tipoCalculo;
            this.imagenCalculo      = "/static/icon/calcular_nomina.png";
            this.tipoCalculo = tipoCalculo;
            await this.getEmpleados(datos);
            this.itemCalculo =  datos;
            this.nombreEmpresa = datos.nombre_empresa;
            this.periodicidad = datos.periodicidad;
            this.mesAcumulacion = datos.mes_de_acomulacion;
            this.periodoFechaPago = datos.fecha_de_pago;
            this.isLoadingCalculo   = false;
            setTimeout(() => { document.getElementById("btnModalCalculo").click(); }, 100);
        },

        /**
         * @method abrirModalTimbre Este método realiza la acción para abrir un modal de alerta para realizar la "acumulación" o "acumulación y timbrado".
         * @description Este método realiza la acción para abrir un modal de alerta para realizar la "acumulación" o "acumulación y timbrado".
         * @param {Object} item Esta variable es un objeto, son los datos de un registro para el cálculo de nómina.
        */
        abrirModalTimbre(item){
            this.imagen = "/static/icon/alert_warning.png";
            this.tituloModal = "ADVERTENCIA"
            this.dialogTimbre =  true;
            this.itemPrecalculo = item;
        },
        
        /**
         * @method consultar Método para reconsultar.
         * @description Método para reconsultar el registro para el cálculo de nómina. El resultado de la función se muestra en el 
         * modal de precalculo de nomina.
         */
        consultar(){

            this.tamModal = "700px";
           
            if(this.itemCalculo.tipo_periodo_especial == null){
                this.showDatos = "NOMINA";
                this.consultarNominaNormal();
            }
            else if(this.itemCalculo.tipo_periodo_especial == "FINIQUITO"){
                this.showDatos = "FINIQUITO";
                this.consultaFiniquito();
            }
            else if(this.itemCalculo.tipo_periodo_especial == "AGUINALDO"){
                this.showDatos = "AGUINALDO";
                this.consultaAguinaldo();
            }
            else if(this.itemCalculo.tipo_periodo_especial == "PTU"){
                this.showDatos = "PTU";
                this.consultaPTU();
            }

        },

        consultarNominaNormal(){
            let self = this;
            this.isLoadingCalculoTabla = true;
            setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
            let empleados = [];
            this.modelo_empleados.forEach((element) => {
                empleados.push({empleado_id: element});
            });

            let tipoConsulta = 'RECONSULTAR';
            
            let parametros = { 
                id : this.itemCalculo.id, 
                accion: tipoConsulta, 
                tipo_reporte: "empleados", 
                tipo_nomina : 'GENERAL', 
                empleados : empleados 
            }
            apiCalculoNomina.calcular(parametros).then((response) => {
                
                //this.itemsEmpleados = response.data;
                self.periodoFechaInicial    = response.periodo.fecha_inicial;
                self.periodoFechaFinal      = response.periodo.fecha_final;
                self.totalPercepciones       = response.totales.percepciones;
                self.totalDeducciones        = response.totales.deducciones;
                self.totalOtrosPagos         = response.totales.otros_pagos;
                self.neto                    = response.totales.neto;
                self.totalEmpleados          = 0;

                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];

                        if(parseFloat(empleado.empleado.total_sueldo) != 0){
                            empleado.itemsPercepciones.push({
                                codigo : 'P1000',
                                nombre : 'Sueldo',
                                total  : empleado.empleado.total_sueldo
                            });
                        }
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    let complemento = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsPercepciones.push(complemento);
                                    }
                                    
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })

                        if(empleado.ajuste_mensual){
                            empleado.ajuste_mensual.forEach(incidencia =>{
                                switch (incidencia.codigo.charAt(0)) {
                                    case 'P':
                                        let complemento = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                        break;
                                    case 'D':
                                        let complemento2 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsDeducciones.push(complemento2);
                                        }
                                        break;
                                    case 'O':
                                        let complemento3 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsOtrosPagos.push(complemento3);
                                        }
                                        break;  
                                    case 'I':
                                        let complemento4 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsInformativos.push(complemento4);
                                        }
                                        break;  
                                    default:
                                        break;
                                }  
                            })
                        }
                        

                        if(parseFloat(empleado.subsidio) != 0){
                            empleado.itemsOtrosPagos.push({
                                codigo : 'O999',
                                nombre : 'Subsidio a entregar',
                                total  : empleado.subsidio
                            });
                        }
                        if(parseFloat(empleado.isr) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D998',
                                nombre : 'ISR',
                                total  : empleado.isr
                            });
                        }
                        if(parseFloat(empleado.imss) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D999',
                                nombre : 'IMSS',
                                total  : empleado.imss
                            });
                        }
                    })
                }

                this.itemsEmpleados = formatEmpleados;
                this.isLoadingCalculoTabla = false;
                if(!response.valid){
                    Notify.Error("ADVERTENCIA","Uno o más empleados presentan problemas en el cálculo de su nómina", null, 'Enterado')
                }
                
                
            })
            .catch(err => {
                console.log(err)
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.isSaving = false;
                this.cerrarModalTabla();
            });
        },
        consultaAguinaldo(){
            let self = this;
            this.itemFiniquito = null;
            setTimeout(()=>{ document.getElementById("btnModalCalculoTabla").click(); }, 100);

            let formatEmpleados = [];
            this.isLoadingCalculoTabla = true;
            
            this.modelo_empleados.forEach( item => {
                let obj = {"empleado_id":item};
                formatEmpleados.push(obj);
            })
            

            let parametros = {
                        "accion":"RECONSULTAR",
                        "tipo_reporte": "empleados",
                        "tipo_nomina": this.itemCalculo.tipo_periodo_especial,
                        "periodo_id": this.itemCalculo.periodo_id,
                        "tipo_nomina_id": this.itemCalculo.tipo_nomina_id,
                        "empleados": formatEmpleados
                    }


            apiCalculoNomina.calcularAguinaldo(parametros).then((response) => {
                this.itemsEmpleados = response.data;

                self.totalEmpleados         = response.data.length;
                self.totalPercepciones      = response.totales.percepciones;
                self.totalDeducciones       = response.totales.deducciones;
                self.neto                   = response.totales.neto;


                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];
                        empleado.p_IndemnizacionServicio = 0;
                        empleado.p_PrimaAntiguedad  = 0;
                        empleado.p_Indemnizacion    = 0;

                        
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    if(incidencia.funcion_nombre == "p_IndemnizacionServicio"){
                                        empleado.p_IndemnizacionServicio = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else if(incidencia.funcion_nombre == "p_PrimaAntiguedad"){
                                        empleado.p_PrimaAntiguedad  = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else if(incidencia.funcion_nombre == "p_Indemnizacion"){
                                        empleado.p_Indemnizacion    = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else{
                                        let complemento = {
                                            codigo : incidencia.codigo_incidencia,
                                            nombre : incidencia.nombre_incidencia,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                    }
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })
                        if(parseFloat(empleado.isr_retener) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'O999',
                                nombre : 'ISR',
                                total  : empleado.isr_retener
                            });
                        }
                    })
                }

                this.isLoadingCalculoTabla = false;
                this.btnConsultar       = false;
                this.invaliBtnGuardar   = false;
                this.btnCerrar          = false;
            })
            .catch (err => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.cerrarModalTabla();
            })
        },
        consultaFiniquito(){
            let self = this;
            this.itemFiniquito = null;
            setTimeout(()=>{ document.getElementById("btnModalCalculoTabla").click(); }, 100);

            let formatEmpleados = [];
            this.isLoadingCalculoTabla = true;
            
            this.modelo_empleados.forEach( item => {
                let obj = {"empleado_id":item};
                formatEmpleados.push(obj);
            })

            let parametros = {
                        "accion":"RECONSULTAR",
                        "tipo_reporte": "empleados",
                        "tipo_nomina": this.itemCalculo.tipo_periodo_especial,
                        "periodo_id": this.itemCalculo.periodo_id,
                        "tipo_nomina_id": this.itemCalculo.tipo_nomina_id,
                        "empleados": formatEmpleados
                    }
            
            this.itemFiniquito = this.itemCalculo;
            apiCalculoNomina.calcularFiniquito(parametros).then((response) => {
                self.totalPercepciones       = response.totales.percepciones;
                self.totalDeducciones        = response.totales.deducciones;
                self.totalOtrosPagos         = response.totales.otros_pagos;
                self.neto                    = response.totales.neto;

                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];
                        empleado.p_IndemnizacionServicio = 0;
                        empleado.p_PrimaAntiguedad  = 0;
                        empleado.p_Indemnizacion    = 0;

                        empleado.itemsPercepcionesIndemnizacion     = [];
                        empleado.itemsDeduccionesIndemnizacion      = [];
                        empleado.itemsOtrosPagosIndemnizacion       = [];
                        empleado.itemsInformativosIndemnizacion     = [];

                        if(parseFloat(empleado.empleado.total_sueldo) != 0){
                            empleado.itemsPercepciones.push({
                                codigo : 'P1000',
                                nombre : 'Sueldo',
                                total  : empleado.empleado.total_sueldo
                            });
                        }
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    if(incidencia.funcion_nombre == "p_IndemnizacionServicio"){
                                        empleado.p_IndemnizacionServicio = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else if(incidencia.funcion_nombre == "p_PrimaAntiguedad"){
                                        empleado.p_PrimaAntiguedad  = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else if(incidencia.funcion_nombre == "p_Indemnizacion"){
                                        empleado.p_Indemnizacion    = incidencia.total;
                                        this.tamModal = "1000px";
                                    }
                                    else{
                                        let complemento = {
                                            codigo : incidencia.codigo_incidencia,
                                            nombre : incidencia.nombre_incidencia,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                    }
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })

                        if(empleado.indemnizacion != null && empleado.indemnizacion.length != 0){
                            this.tamModal = "1000px";
                            empleado.indemnizacion.incidencias.forEach(incidenciaIndemnizacion => {

                                switch (incidenciaIndemnizacion.codigo_incidencia.charAt(0)) {
                                    case 'P':
                                        let complemento = {
                                            codigo : incidenciaIndemnizacion.codigo_incidencia,
                                            nombre : incidenciaIndemnizacion.nombre_incidencia,
                                            total  : incidenciaIndemnizacion.total
                                        }
                                        if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                            empleado.itemsPercepcionesIndemnizacion.push(complemento);
                                        }
                                        break;
                                    case 'D':
                                        let complemento2 = {
                                            codigo : incidenciaIndemnizacion.codigo_incidencia,
                                            nombre : incidenciaIndemnizacion.nombre_incidencia,
                                            total  : incidenciaIndemnizacion.total
                                        }
                                        if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                            empleado.itemsDeduccionesIndemnizacion.push(complemento2);
                                        }
                                        break;
                                    case 'O':
                                        let complemento3 = {
                                            codigo : incidenciaIndemnizacion.codigo_incidencia,
                                            nombre : incidenciaIndemnizacion.nombre_incidencia,
                                            total  : incidenciaIndemnizacion.total
                                        }
                                        if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                            empleado.itemsOtrosPagosIndemnizacion.push(complemento3);
                                        }
                                        break;  
                                    case 'I':
                                        let complemento4 = {
                                            codigo : incidenciaIndemnizacion.codigo_incidencia,
                                            nombre : incidenciaIndemnizacion.nombre_incidencia,
                                            total  : incidenciaIndemnizacion.total
                                        }
                                        if(parseFloat(incidenciaIndemnizacion.total) != 0){
                                            empleado.itemsInformativosIndemnizacion.push(complemento4);
                                        }
                                        break;  
                                    default:
                                        break;
                                }

                            })
                        }


                        
                        

                        if(parseFloat(empleado.subsidio) != 0){
                            empleado.itemsOtrosPagos.push({
                                codigo : 'O999',
                                nombre : 'Subsidio a entregar',
                                total  : empleado.subsidio
                            });
                        }
                        if(parseFloat(empleado.isr) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D998',
                                nombre : 'ISR',
                                total  : empleado.isr
                            });
                        }
                        if(parseFloat(empleado.imss) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D999',
                                nombre : 'IMSS',
                                total  : empleado.imss
                            });
                        }
                    })
                }

                this.itemsEmpleados = formatEmpleados;
                this.isLoadingCalculoTabla = false;
                this.btnConsultar       = false;
                this.invaliBtnGuardar   = false;
                this.btnCerrar          = false;
            })
            .catch (err => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.cerrarModalTabla();
            })
        },
        async consultaPTU(){
            this.isLoadingCalculoTabla = true;
            this.totalPercepciones  = 0;
            this.totalDeducciones   = 0;
            this.neto               = 0;
            let parametros = { 
                accion              : 'RECONSULTAR',
                tipo_nomina         : 'PTU',
                descargar           : false,
                id                  : this.itemCalculo.id,
            }
            await apiPTU.consultarPTU(parametros).then(async (response) => {
                if(response.data.length > 0){
                    this.nombreEmpresa      = this.itemCalculo.nombre_empresa;
                    this.totalPercepciones  = response.totales.percepciones;
                    this.totalDeducciones   = response.totales.deducciones;
                    this.neto               = response.totales.neto;
                    this.itemsEmpleados     = response.data;
                }
                if(response.data.length == 0){
                    Notify.ErrorToast('No hay elementos para mostrar');
                    this.nombreEmpresa      = null;
                    this.totalPercepciones  = 0;
                    this.totalDeducciones   = 0;
                    this.neto               = 0;
                }

                this.isLoadingCalculoTabla = false;
            })
            .catch(err => {
                this.isLoadingCalculoTabla = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            }); 
        },

        descargaMasivaFiniquito(){
            let empleados = [];
            this.itemsEmpleados.forEach(item => {
                empleados.push({empleado_id:item.empleado.id});
            });
            this.descargarCartaFiniquito(empleados);
        },

        async descargarCartaFiniquito(empleados) {
                let parametros = {
                    "accion":"RECONSULTAR",
                    "tipo_reporte": "empleados",
                    "tipo_nomina": "FINIQUITO",
                    "periodo_id": this.itemCalculo.periodo_id,
                    "tipo_nomina_id": this.itemCalculo.tipo_nomina_id,
                    "empleados": empleados
                }
           

            let urlCalculo = "calculo-nomina/calcular-finiquito/descargar";
            
            
            const FileDownload = require("js-file-download"); 
            let nombre;
            nombre = this.itemCalculo.empresa + "-" + "PTU" + "-" + this.itemCalculo.numero_periodo + ".pdf";
            this.fraseLoading = "Descargando";
            this.loadingLottie = true;
            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.cerrarModal();
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;
                })
                .catch(async (error) =>{
                    
                    this.btnGuardar             = false;
                    this.invalidBtnConsultar    = false;
                    this.btnCerrar              = false; 
                    this.dialogDownload         = false;
                    this.loadingLottie          = false;

                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar el cálculo");
                console.log(err);
                this.btnGuardar             = false;
                this.invalidBtnConsultar    = false;
                this.btnCerrar              = false;
                this.dialogDownload         = false;
                this.loadingLottie          = false;
                this.cerrarModal();
            }
        },

        /**
         * @method cerrarModalTabla Método para cerrar modal
         * @description Método que cierra el modal del precalculo de nómina.
         */
         cerrarModalTabla(){
            this.dialogCalculoTabla = false;        
        },


        async abrirModalAF(accion, data = null){

            this.accion     = accion;
            this.isLoading  = true;

            apiPeriodos.get(data.periodo_id).then((response) => {
                this.aguinaldoFiniquito.id                      = response.id;
                this.aguinaldoFiniquito.tipo_nomina_id          = response.tipo_nomina_id;
                this.aguinaldoFiniquito.periodicidad_id         = response.periodicidad_id;
                this.aguinaldoFiniquito.tipo_nomina_SAT_id      = response.tipo_nomina_SAT_id;
                this.aguinaldoFiniquito.numero                  = response.numero;
                this.aguinaldoFiniquito.fecha_inicial           = response.fecha_inicial;
                this.aguinaldoFiniquito.dias_de_pago            = response.dias_de_pago;
                this.aguinaldoFiniquito.fecha_final             = response.fecha_final;
                this.aguinaldoFiniquito.especial                = true;
                this.aguinaldoFiniquito.inicio_de_anio          = false;
                this.aguinaldoFiniquito.fin_de_anio             = false;
                this.aguinaldoFiniquito.inicio_de_bimestre      = false;
                this.aguinaldoFiniquito.fin_de_bimestre         = false;
                this.aguinaldoFiniquito.inicio_de_mes           = false;
                this.aguinaldoFiniquito.fin_de_mes              = false;
                this.aguinaldoFiniquito.mes_de_acomulacion      = response.mes_de_acomulacion;
                this.aguinaldoFiniquito.descripcion             = response.descripcion;
                this.aguinaldoFiniquito.fecha_de_pago           = response.fecha_de_pago;
                this.aguinaldoFiniquito.numero_de_factura       = response.numero_de_factura;
                this.aguinaldoFiniquito.tipo_periodo_especial   = response.tipo_periodo_especial;
                this.aguinaldoFiniquito.descontar_faltas        = response.descontar_faltas;
                this.aguinaldoFiniquito.descontar_incapacidades = response.descontar_incapacidades;
                this.aguinaldoFiniquito.actual                  = response.actual;
                this.aguinaldoFiniquito.anterior_actual         = response.actual;
                this.aguinaldoFiniquito.fecha_baja              = response.fecha_baja;
                this.agregar_indemnizacion_20_dias              = response.agregar_indemnizacion_20_dias;
                this.agregar_sueldos                            = response.agregar_sueldos;

                this.obtenerCodigo.tipo_nomina_id               = response.tipo_nomina_id         
                this.obtenerCodigo.tipo_periodo_especial        = response.tipo_periodo_especial;
                
                if(data.tipo_periodo_especial === "FINIQUITO"){
                    this.updateAguinaldo = true;
                    this.updateFiniquito = false;
                }
                else{
                    this.updateFiniquito = true;
                    this.updateAguinaldo = false;
                }

                this.periodicidad_id = response.periodicidad_id;
                this.tipo_nomina_SAT_id = response.tipo_nomina_SAT_id;

                this.opcionFiniquito        = response.tipo_separacion;
                this.opcionDespido          = response.tipo_liquidacion;
                
                if(response.dias_finiquito == 90 && response.tipo_liquidacion == 'NEGOCIACION'){
                    this.opcionDias = "otro"
                    this.diasOtro = response.dias_finiquito;
                }
                else if(response.dias_finiquito == 60){
                    this.opcionDias = "60";
                }
                else if(response.dias_finiquito == 45){
                    this.opcionDias = "45";
                }
                else{
                    this.opcionDias = "otro"
                    this.diasOtro = response.dias_finiquito;
                }
            
            })
            .catch((err) => {
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });



           
            this.isLoading  = false;
            setTimeout(() => { this.dialogAF = true }, 100);
        },
        guardarAF() {
            this.$refs.formAF.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.aguinaldoFiniquito.periodicidad_id = this.periodicidad_id;
                this.aguinaldoFiniquito.tipo_nomina_SAT_id = this.tipo_nomina_SAT_id;
                this.aguinaldoFiniquito.tipo_nomina_id = this.obtenerCodigo.tipo_nomina_id;
                this.aguinaldoFiniquito.tipo_periodo_especial = this.obtenerCodigo.tipo_periodo_especial;
                this.aguinaldoFiniquito.dias_de_pago = 0;

                if(this.agregar_sueldos){
                    this.aguinaldoFiniquito.fecha_inicial = this.aguinaldoFiniquito.fecha_inicial;
                    this.aguinaldoFiniquito.fecha_final = this.aguinaldoFiniquito.fecha_final;
                }
                else{
                    this.aguinaldoFiniquito.fecha_inicial = this.aguinaldoFiniquito.fecha_de_pago;
                    this.aguinaldoFiniquito.fecha_final = this.aguinaldoFiniquito.fecha_de_pago;
                }

                //finiquito
                if(this.obtenerCodigo.tipo_periodo_especial == 'FINIQUITO'){
                    this.aguinaldoFiniquito.tipo_separacion = this.opcionFiniquito;//voluntario o despido

                    this.aguinaldoFiniquito.agregar_indemnizacion_20_dias = this.agregar_indemnizacion_20_dias;
                    this.aguinaldoFiniquito.agregar_sueldos = this.agregar_sueldos;

                    if(this.opcionFiniquito == 'DESPIDO'){
                        this.aguinaldoFiniquito.tipo_liquidacion = this.opcionDespido;//completo o negociacion
                        if( this.opcionDespido == "COMPLETA"){
                            this.aguinaldoFiniquito.dias_finiquito = 90;
                        }
                        else{
                            if(this.opcionDias == "60"){
                                this.aguinaldoFiniquito.dias_finiquito = 60;
                            }
                            else if(this.opcionDias == "45"){
                                this.aguinaldoFiniquito.dias_finiquito = 45;
                            }
                            else{
                                this.aguinaldoFiniquito.dias_finiquito = this.diasOtro;
                            }
                        }
                    }
                }
                if(this.obtenerCodigo.tipo_periodo_especial == 'AGUINALDO'){
                    this.aguinaldoFiniquito.agregar_sueldos = false;
                }

                if(this.accion === "update") {
                    apiPeriodos.updateFiniquito(this.aguinaldoFiniquito).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se actualizó satisfactoriamente.");
                        this.$refs.tabla2.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            if(this.$refs.formAF){
                                this.$refs.formAF.reset();
                            }
                        });
                    })
                    .catch((err) => {
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },

        abrirModalPTU(item){
            this.isLoading  = true;
            this.getAnios(item.empresa_id);
            this.getPuestos(item.empresa_id);

            let ptu_configuracion = JSON.parse(item.ptu_config)

            this.itemPTU.id                         = item.periodo_id;
            this.itemPTU.anio_fiscal_id             = ptu_configuracion.anio_fiscal_id;
            this.itemPTU.tope_ingreso_anual         = ptu_configuracion.tope_ingreso_anual;
            this.itemPTU.importe_repartir           = ptu_configuracion.importe_repartir_total;
            this.itemPTU.fecha_de_pago              = item.fecha_de_pago;
            this.itemPTU.fecha_inicial              = item.fecha_inicial;
            this.itemPTU.fecha_final                = item.fecha_final;
            this.itemPTU.mes_de_acomulacion         = item.mes_de_acomulacion;
            this.itemPTU.tipo_complemento           = ptu_configuracion.tipo_complemento;
            this.puestosExcluidos                   = ptu_configuracion.puestos_excluidos;
            this.itemPTU.dias_minimo_laborados      = ptu_configuracion.dias_minimos_laborados;
            this.itemPTU.solo_acumulados            = ptu_configuracion.solo_acumulados;
            this.itemPTU.incluir_vacaciones_pagadas = ptu_configuracion.incluir_vacaciones_pagadas;
            this.itemPTU.topar_ptu                  = ptu_configuracion.topar_ptu;

            this.itemPTU.dias_de_pago               = item.dias_de_pago;
            this.empleados_menor_60_dias            = ptu_configuracion.empleados_menor_60_dias;

            this.isLoading  = false;
            setTimeout(() => { this.dialogPTU = true }, 100);
        },

        guardarPTU() {
            this.$refs.formPTU.validate().then(async success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.itemPTU.puestos_excluidos = this.puestosExcluidos 
                
                await apiPTU.editarPTU(this.itemPTU).then((response) => {
                    Notify.Success("Operación exitosa", "El PTU se actualizó satisfactoriamente.");
                    this.$refs.tabla3.getData();
                    this.cerrarModal();
                })
                .catch(err => {
                    this.isSaving = false;
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al actualizar el PTU.");
                });
            })
        },
        async getAnios(empresa_id){
            this.itemsAnios = [];
            let parametros = { activo:true, paginate:false, order:"desc", empresa_id: empresa_id }
            await apiPTU.findAnios(parametros).then((response) => {
                this.itemsAnios = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los años.");
            });
        },
        async getPuestos(empresa_id) {
            if(empresa_id == null){
                return;
            }
            this.itemsPuestos = [];
            let param = { activo: true, paginate: false, empresa_id: empresa_id };
            await apiPuestos.find(param).then(response => {
                this.itemsPuestos = response.data;
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los puestos.");
            });
        },
        socketProcesandoNomina(itemNomina){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });

            window.Echo.channel(`progreso-nomina-${itemNomina.id}`).listen('NominaTimbreProgress', (e) => {
                this.mostrarProceso = true;
                this.nominaPorcentaje   = e.message.porcentaje;
                this.nominaTotal        = e.message.total;      
                this.nominaProcesados   = e.message.procesados;
                if(e.message.porcentaje == 100){
                    this.procesados = e.message.procesados;
                    this.conError   = e.message.errores;
                    this.dialogMensaje = true;
                    setTimeout(()=>{ this.clickOverlayProceso() }, 5000);
                }

            });
        },
        clickOverlayProceso(){
            //this.getEmpleados();
            this.mostrarProceso = false;

            if(this.tab == 0){
                this.$refs.tabla1.getData();
            }
            else if(this.tab == 1){
                this.$refs.tabla2.getData();
            }
            else if(this.tab == 2){    
                this.$refs.tabla3.getData();
            }
        },
        cerrarModalMensaje(){
            this.dialogMensaje = false;
            this.procesados = 0;
            this.conError   = 0;
            this.mostrarProceso = false;
        },
        netoPago(neto_pagar = 0, indemnizacion = 0){
            return this.formatNum(parseFloat(neto_pagar) + parseFloat(indemnizacion));
        },
    },
    created(){
        this.urlAvatar = GetUrlImg.getURL();
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if (this.rol != "root") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
            if(this.rol == 'admin-empresa' || this.rol == 'admin-sucusal' || this.rol == 'admin-depto'){
                this.filters.empresa_id =  this.datosLogin.empresa_id;
            }
        }
        if(this.rol == 'root'){
            this.getClientes();
        }
    },
    /**
     * @description updated(). Se ejecuta cada vez que hay un cambio en el Virtual DOM.
    */
    updated() {    
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingLeft   = "8px";

        if(document.querySelector('#tableScrollSimple')){
            document.querySelector('#tableScrollSimple tbody').style.maxHeight    = "none";
            document.querySelector('#tableScrollSimple tbody').style.minWidth     = "100%";
            document.querySelector('#tableScrollSimple tbody').style.background   = "#fff";
        }
    }

}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .bordeVchip.v-chip.v-chip--outlined{
        border-width: none;
        border-style: none;
    }
    .titleModalBuscar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }
    .radioLabel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .pNota{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #D93025;
    }
    .item-subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #1E2245;
        margin-bottom:0px;
    }
    .item-subtitle-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .v-btn-periodo:hover:before {
      background-color: transparent;
      
    }
    .v-btn-periodo:before {
        display: none;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .menu-width{
        max-width: 500px;
        border-radius: 20px !important;
    }
    .span-periodo{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        text-decoration-line: underline;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    #btnModalErrores{
        display: none;
    }

    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    .btnContrats.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        height: 36px;
        color: #FFFFFF;
        text-transform: capitalize;
    }
    .tbl-header-reconsulta {
        background: #F6F6F6;
        height: 58px;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
    .th-reconsulta {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding-left: 50px;
        text-align: left !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }

    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
    }
    .subtitle-divider{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .text-laborados{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        letter-spacing: 0.1px !important;
        color: #828282 !important;
    }

    th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-modal tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .tbl-modal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-modal .tbl-header-modal {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableModal{
        color: #c5c5c5 !important;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .cssTitleProgress{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
</style>
